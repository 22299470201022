import React, { createContext, useState, useContext } from "react";

const FetchDataContext = createContext();

export const FetchDataContextProvider = ({ children }) => {
const [isAiGeneratedOn, setAiGeneratedOn] = useState(false);

  return (
    <FetchDataContext.Provider value={{ isAiGeneratedOn, setAiGeneratedOn }}>
      {children}
    </FetchDataContext.Provider>
  );
};

export const useFetchDataContext = () => useContext(FetchDataContext);