import React, { useState, useEffect, useRef } from "react";
import api from "../../helper/auth_interceptor";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { getUser } from "../../helper/auth_helper";
import rightArrow from "../../images/right-arrow.png";
import home from "../../images/home.png";
import { useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast } from "react-toastify";
import "./manageUser.modules.css";
import moment from "moment";
import { Export } from "./api/handleExport";
import UserTable from "./component/userListTable";
import { PaginationTool } from "../../utility/pagination";
import { UserListFilter } from "./component/userListFilter";
import useExport from "../shared/useExport";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

function ManageUsers(suprmenAdmin) {
  const orgId = useRef("");

  const navigate = useNavigate();

  const [showCalendar, setShowCalendar] = useState(false);
  const dateRangeRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState({
    userName: true,
    email: true,
    startDate: true,
    endDate: true,
    role: true,
    organisation: true,
    status: true,
    actions: true,
  });

  const [user, setUser] = useState("");
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortingDetails, setSortingDetails] = useState({
    column: null,
    order: null,
  });
  const [isAscending, setIsAscending] = useState(true);

  const requestBody = useRef({
    organizationName: null,
    location: null,
    licenceType: null,
    startDate: null,
    endDate: null,
  });

  const toggleClearFilter = () => {
    window.location.reload();
  };

  const onPageChange = (page) => {
    currentPage.current = page;
    fetchUserDetails(page);
  };

  const [pageNumbers, setPageNumbers] = useState(0);
  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber);
  };

  const { handleExport: handleExportCallback } = useExport();

  const handleClickExport = async () => {
    await Export(sortingDetails, requestBody, orgId, handleExportCallback);
  };

  const currentPage = useRef(1);

  const pageNumber = currentPage.current;

  const selectedPageSize = useRef(10);

  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchUserDetails(1);
  };

  async function fetchUserDetails(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;

    const token = user.access_token;
    const decodedToken = jwt_decode(token);
    orgId.current = decodedToken.orgId;

    const selectedSize = selectedPageSize.current;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/filter/${orgId.current}?pageNumber=${pageForUrl}&pageSize=${selectedSize}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers,
      });

      setUser(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const initializeSelectedColumns = async () => {
    const user = await getUser();

    const page = "USER";
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/${page}`;

    try {
      const response = await api.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const savedSettings = response.data.selectedColumns.split(",");
      const defaultColumns = {
        userName: true,
        email: true,
        startDate: true,
        endDate: true,
        role: true,
        organisation: true,
        status: true,
        actions: true,
      };

      if (savedSettings.length > 0) {
        const updatedColumns = { ...defaultColumns };
        Object.keys(defaultColumns).forEach((column) => {
          updatedColumns[column] = savedSettings.includes(column);
        });
        return updatedColumns;
      }

      return defaultColumns;
    } catch (error) {
      console.error("Error fetching column visibility:", error);
      return {
        userName: true,
        email: true,
        startDate: true,
        endDate: true,
        role: true,
        organisation: true,
        status: true,
        actions: true,
      };
    }
  };

  useEffect(() => {
    const fetchInitialColumns = async () => {
      const initialColumns = await initializeSelectedColumns();
      setSelectedColumns(initialColumns);
    };

    fetchInitialColumns();
  }, []);

  const saveColumnVisibility = async (updatedColumns) => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/selected-columns`;

    const userPreference = {
      page: "USER",
      selectedColumns: Object.keys(updatedColumns)
        .filter((key) => updatedColumns[key])
        .join(","),
    };

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      await api.post(apiUrl, userPreference, { headers });
    } catch (error) {
      console.error("Error saving column selection:", error);
    }
  };

  const handleResetColumns = () => {
    const defaultColumns = {
      userName: true,
      email: true,
      startDate: true,
      endDate: true,
      role: true,
      organisation: true,
      status: true,
      actions: true,
    };

    setSelectedColumns(defaultColumns);
    saveColumnVisibility(defaultColumns);
  };

  const columns = Object.keys(selectedColumns).filter(
    (column) => selectedColumns[column]
  );

  const handleToggleColumn = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = {
        ...prevSelectedColumns,
        [column]: !prevSelectedColumns[column],
      };
      saveColumnVisibility(updatedColumns);
      return updatedColumns;
    });
  };

  useEffect(() => {
    fetchUserDetails(currentPage.current);
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleSort = async (column, page) => {
    const user = await getUser();
    const token = user.access_token;
    const decodedToken = jwt_decode(token);
    orgId.current = decodedToken.orgId;
    const pageForUrl = page || currentPage.current;
    const selectedSize = selectedPageSize.current;
    const newIsAscending = column === sortedColumn ? !isAscending : true;
    setSortedColumn(column);
    setIsAscending(newIsAscending);
    const sortOrder = newIsAscending ? "asc" : "desc";
    setSortingDetails({
      column: column,
      order: newIsAscending ? "asc" : "desc",
    });
    const sortApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/filter/${orgId.current}?pageNumber=${pageForUrl}&pageSize=${selectedSize}&sortBy=${column}&sortOrder=${sortOrder}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.post(sortApi, requestBody.current, {
        headers,
      });

      setUser(response.data.data);
      setPageNumbers(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditClick = (rowData) => {
    navigate("/editUser/" + rowData.userId, { state: { rowData: user } });
  };

  const handleDeleteUser = async (rowData) => {
    const userStatus = rowData.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
   
    
      if (suprmenAdmin?.suprmenAdmin || suprmenAdmin?.orgAdmin) {
        const user = await getUser();
        const delApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/status/${rowData.userId}?status=${userStatus}`;

        fetch(delApi, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        })
          .then((data) => {
            if (data.status === 200) {
              toast(`user status is changed to ${userStatus}!`);
              fetchUserDetails(currentPage.current);
            } else {
              toast.error(data?.statusText);
            }
          })
          .catch((error) => {
            console.error("Error making PUT request:", error);
          });
      } else {
        toast("Only SuprMen or org admin can inactivate the user!");
      }
    
  };

  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      startDate: startDate,
      endDate: endDate,
    };
    fetchUserDetails();
  };

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;

    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchUserDetails();
  };

  const onSelectStatus = (e) => {
    requestBody.current = {
      ...requestBody.current,
      status: e.target.value === "" ? null : e.target.value,
    };
    fetchUserDetails();
  };

  const onSelectRole = (e) => {
    requestBody.current = {
      ...requestBody.current,
      role: e.target.value === "" ? null : e.target.value,
    };
    fetchUserDetails();
  };

  return (
    <div class="main-wrap">
      <div className="quote-body">
        <input
          id="slide-sidebar"
          className="checkInputManageUserList"
          type="checkbox"
          role="button"
        />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>

        <div class="portfolio">
          <div class="main-content">
            <div class="main-header-content">
              <div className="pageHeader">
                <h1>{t("pageHeader_manageUsers")}</h1>
              </div>
              <div class="user">
                <button
                  className="btn btn-light quoteBtns rqstbtn "
                  type="button"
                >
                  <img src={home} alt="home icon" />
                  <img src={rightArrow} alt="right arrow icon" />
                  <span>{t("pageHeaderBtn_manageUsers")}</span>
                </button>
              </div>
            </div>
            <div className="page-body content-ht">
              <div className="formDiv">
                <div class="userlist-record">
                  <div class="userlist-details">
                    <div className="form-head list-user">
                      <div>
                        <h2 className="pageHeading">
                          {t("tableHeading_manageUsers")}
                        </h2>
                      </div>
                    </div>
                    <UserListFilter
                      t={t}
                      requestBody={requestBody}
                      handleSearchFormSubmit={handleSearchFormSubmit}
                      onSelectStatus={onSelectStatus}
                      onSelectRole={onSelectRole}
                      setShowCalendar={setShowCalendar}
                      showCalendar={showCalendar}
                      dateRangeRef={dateRangeRef}
                      selectedDateRange={selectedDateRange}
                      handleDateChange={handleDateChange}
                      toggleClearFilter={toggleClearFilter}
                      handleClickExport={handleClickExport}
                    />

                    <UserTable
                      handleSort={handleSort}
                      sortedColumn={sortedColumn}
                      isAscending={isAscending}
                      t={t}
                      suprmenAdmin={suprmenAdmin}
                      handleEditClick={handleEditClick}
                      user={user}
                      handleDeleteUser={handleDeleteUser}
                      columns={columns}
                      selectedColumns={selectedColumns}
                      onToggleColumn={handleToggleColumn}
                      handleResetColumns={handleResetColumns}
                    />

                    <PaginationTool
                      pageNumbers={pageNumbers}
                      pageNumber={pageNumber}
                      handlePageChange={handlePageChange}
                      handlePageSize={handlePageSize}
                      t={t}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageUsers;
