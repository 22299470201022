import React, { useState, useRef, useEffect } from "react";
import checkboxSelection from "./../../images/checkbox-selection-dropdown.png";
import { useTranslation } from "react-i18next";
import styles from "./dropdownFilter.module.css";
import searchbar from "./../../images/filter-searchBar.png";
import arrowSetting from "./../../images/downarrow-setting.png";
import upArrow from "./../../images/upArrow.png";

export const LocationFilterDropdown = ({
  columns,
  isDropdownOpen,
  handleSearchLocation,
  LocationfilterRequestBody,
  ResetFilter,
  handleLocationFilterSearch,
  resetFilter,
  allReset,
  setAllReset,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOptionsOpen, setOptionOpen] = useState(true);

  const toggleOptionOpen = () => {
    setOptionOpen((prev) => !prev);
  };

  const handleCheckboxChange = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = prevSelectedColumns.includes(column)
        ? prevSelectedColumns.filter((item) => item !== column)
        : [...prevSelectedColumns, column];

      handleLocationFilterSearch(updatedColumns);
      setIsAllSelected(updatedColumns.length === columns.length);
      return updatedColumns;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedColumns([]);
      handleLocationFilterSearch([]);
    } else {
      setSelectedColumns(columns);
      handleLocationFilterSearch(columns);
    }
    setIsAllSelected(!isAllSelected);
  };

  const resetAll = () => {
    setSelectedColumns([]);
    setIsAllSelected(false);
    ResetFilter("location");
    resetFilter("location");
  };

  useEffect(() => {
    if (allReset === true) {
      setSelectedColumns([]);
      setIsAllSelected(false);
      ResetFilter();
      setAllReset(false);
      setOptionOpen(true);
    }
  }, [ResetFilter, allReset, setAllReset]);

  return (
    <div className={`${styles.selectionFilterButton} ${styles.locationFilter}`}>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`${styles.selectionDropdown} ${styles.locationDropdown}`}
        >
          <div className={styles.selectionContainer}>
            <div className={styles.selectionContainerContent}>
              <label>{t("locationFilterSettings")}</label>
              {isOptionsOpen ? (
                <img className={styles.upArrow}
                  src={upArrow}
                  alt="arrow Icon"
                  onClick={toggleOptionOpen}
                />
              ) : (
                <img className={styles.downArrow}
                  src={arrowSetting}
                  alt="arrow Icon"
                  onClick={toggleOptionOpen}
                />
              )}
            </div>
            {isOptionsOpen && (
              <ul>
                <div class={styles.selection}>
                  <button
                    onClick={() => resetAll()}
                    class={styles.resetSelection}
                  >
                    {t("resetToDefault")}
                  </button>
                </div>
                <div className={styles.searchBoxFilter}>
                  <img src={searchbar} alt="home icon" />
                  <input
                    className={styles.checkInputProjectList}
                    type="text"
                    placeholder={t("searchForFilterOptions")}
                    value={LocationfilterRequestBody.current.searchText || ""}
                    onChange={handleSearchLocation}
                  />
                </div>
                <div className={styles.mainSelectionLIContent}>
                  <div className={styles.selectionLiContent}>
                    <li>
                      <label>
                        <img
                          src={checkboxSelection}
                          alt="Checkbox Selection Dropdown"
                        />
                        <input
                          type="checkbox"
                          checked={isAllSelected}
                          onChange={handleSelectAllChange}
                        />
                        {t("all")}
                      </label>
                    </li>
                    {columns.map((column, index) => (
                      <li key={column}>
                        <label>
                          <img
                            src={checkboxSelection}
                            alt="Checkbox Selection Dropdown"
                          />
                          <input
                            type="checkbox"
                            checked={selectedColumns.includes(column)}
                            onChange={() => handleCheckboxChange(column)}
                          />
                          <span title={column}>
                          {column.length > 33 ? column.substring(0, 33) + "..." : column}
                        </span>
                        </label>
                      </li>
                    ))}
                  </div>
                </div>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
