export const getFormattedFormData = (
  userDetails,
  isConvertionOn,
  newOrgId,
  sDate,
  eDate
) => {
  return {
    lastName: userDetails.user.lastName,
    firstName: userDetails.user.firstName,
    email: userDetails.user.email,
    contactNo: userDetails.user.contactNo,
    roleType: userDetails.role.name,
    status: userDetails.user.status,
    startDate: sDate,
    endDate: eDate,
    language: userDetails.user.language,
    jobTitle: userDetails.user.jobTitle,
    ...(isConvertionOn
      ? { orgId: Number(newOrgId) }
      : {
          organization: userDetails.user.organization
            ? { id: userDetails.user.organization.id }
            : null,
        }),
    username: userDetails.email,
  };
};
