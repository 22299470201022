import styles from "./PageHader.module.css";
import { useTranslation } from "react-i18next";

export const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainHeaderContent}>
      <div className={styles.pageHeader}>
        <h1>{t("reports")}</h1>
      </div>
    </div>
  );
};
