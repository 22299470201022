import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export async function fetchLocationFilterData(id, requestBody) {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/location/${id}`;

  try {
    const response = await api.post(apiUrl, requestBody, {
      headers,
    });
    return {
      apiLocationData: response.data,
    };
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
