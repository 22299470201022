import React, {useState, useEffect, useCallback} from 'react';
import { getUser } from '../../../helper/auth_helper';
import { useTranslation } from 'react-i18next';
import api from '../../../helper/auth_interceptor';

export const AdminDetails = ({userDetails, id}) => {
    const [adminDetails, setAdminDetails] = useState({});
    const { t } = useTranslation();
    
    const getOrg = useCallback(async () => {
        const user = await getUser();
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/organization/${id}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };
    
        try {
          const response = await api.get(apiUrl, {
            headers,
          });
    
          setAdminDetails(response.data.admin);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }, [id]); 
    
      useEffect(() => {
        getOrg();
      },[getOrg])
    return(
        <div class="project-leaderdetails-record">
        <div class="projectleader-details">
          <div className="form-head admin-details">
            <h2 className="pageHeading">{t('tabTwoHeader_editOrg')}</h2>
          </div>
          <div class="project-leaderdetails-content">
            <div class="first-row">
              <div class="rows-content">
                
                <label>{t('tabTwoFirstName_editOrg')}{" "}<span className="required">*</span></label>
                <input
                  class="form-control"
                  placeholder={adminDetails.firstName}
                  disabled
                  type="text"
                />
              </div>
              <div class="rows-content">
                
                <label>{t('tabTwoLastName_editOrg')}{" "}<span className="required">*</span></label>
                <input
                  class="form-control"
                  placeholder={adminDetails.lastName}
                  disabled
                  type="text"
                />
              </div>
            </div>
            <div class="second-row">
              <div class="rows-content">
                
                <label>{t('tabTwoEmailAddress_editOrg')}{" "}<span className="required">*</span></label>
                <input
                  class="form-control"
                  placeholder={adminDetails.email}
                  value={userDetails.emailId}
                  disabled
                  type="text"
                />
              </div>
              <div class="rows-content">
                
                <label>{t('tabTwoPhoneNo_editOrg')}{" "}<span className="required">*</span></label>
                <input
                  class="form-control "
                  placeholder={adminDetails.contactNo}
                  value={userDetails.contactNo}
                  disabled
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}