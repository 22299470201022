import styles from "./toggleButton.module.css";
export const ToggleButton = ({ isChecked, handleToggle, isDisabled }) => {
  return (
    <label className={styles.toggle}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
        isDisabled={isDisabled}
      />
      <span className={styles.slider}></span>
    </label>
  );
};
