import React, { useState, useEffect } from "react";
import api from "../../helper/auth_interceptor";
import { getUser, login } from "../../helper/auth_helper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getBuildAddresses } from "../../components/editProject/api/getBuildings";
import babyModalReplacement from "../../images/default-babyModel.png";
import cube from "../../images/cube.png";
import downArrow from "../../images/down.png";

export const MyProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [buildingData, setBuildingData] = useState({});
  const [loadingData, setLoadingData] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [omnibaseUrl, setOmnibaseUrl] = useState("");

  const getVisualizationLink = async () => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/visualization-management/api/get-omnibase-link`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(getApi, { headers });
      setOmnibaseUrl(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getVisualizationLink();
  }, []);

  useEffect(() => {
    fetchMyProjectDetails();
  }, []);

  const getWorkflowStatusDisplayText = (status) => {
    const statusMapping = {
      PROJECT_READY: t("project_ready"),
      PROJECT_STARTED: t("project_started"),
      PROJECT_COMPLETED: t("project_completed"),
    };
    return statusMapping[status] || status;
  };

  async function fetchMyProjectDetails() {
    const user = await getUser();
    if (!user) {
      login();
      return;
    }

    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/getMyProjects`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, { headers });
      console.log("Fetched project data:", response.data);
      setProjects(response.data.myProjectsList);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  }

  const handleDropdownToggle = async (projectId) => {
    if (openDropdowns.includes(projectId)) {
      setOpenDropdowns(openDropdowns.filter((id) => id !== projectId));
    } else {
      setOpenDropdowns([...openDropdowns, projectId]);
      if (!buildingData[projectId]) {
        setLoadingData((prevState) => ({ ...prevState, [projectId]: true }));
        try {
          const { data } = await getBuildAddresses(projectId);
          console.log(`Building data for project ${projectId}:`, data.data);
          setBuildingData((prevState) => ({
            ...prevState,
            [projectId]: data.data,
          }));
        } catch (error) {
          console.error(
            `Error fetching building data for project ${projectId}:`,
            error
          );
        } finally {
          setLoadingData((prevState) => ({ ...prevState, [projectId]: false }));
        }
      }
    }
  };

  const handleEditClick = (rowData) => {
    navigate("/editProject/" + rowData.id, { state: { rowData: projects } });
  };

  return (
    <div className="projectlist-details-table-content">
      <div className="table-content">
        <div className="list">
          <table>
            <thead className="thead-Column">
              <tr className="record-row">
                <th></th>
                <th className="ForNameRow opac">{t("project_table")}</th>
                <th className="ForNameRow opac">{t("projectLeader_table")}</th>
                <th className="ForNameRow opac">{t("workflowStatus_table")}</th>
              </tr>
            </thead>
            <tbody>
              {projects.slice(0, 5).map((project) => (
                <React.Fragment key={project.id}>
                  <tr
                    className={
                      openDropdowns.includes(project.id) ? "clicked-row" : ""
                    }
                  >
                    <td className="dropdown-column" style={{ width: "2%" }}>
                      <button
                        onClick={() => handleDropdownToggle(project.id)}
                        className="dropdownBtn"
                      >
                        <img
                          src={downArrow}
                          alt="toggle building info"
                          className="downImg"
                          style={{
                            transform: openDropdowns.includes(project.id)
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      </button>
                    </td>

                    <td className="ForNameRow">
                      <a
                        className="redirectBtn"
                        onClick={() => handleEditClick(project)}
                      >
                        {project.projectName}
                      </a>
                    </td>

                    <td className="ForNameRow">{project.projectLeaderName}</td>

                    <td className="ForStatusRow">
                      {project.projectStatus
                        ? getWorkflowStatusDisplayText(project.projectStatus)
                        : ""}
                    </td>
                  </tr>

                  {openDropdowns.includes(project.id) && (
                    <tr className="dropDownRow">
                      <td colSpan="4">
                        {loadingData[project.id] ? (
                          <div className="loader-container">
                            <p>Loading ...</p>
                          </div>
                        ) : (
                          Array.isArray(buildingData[project.id]) &&
                          buildingData[project.id].length > 0 && (
                            <div className="list-sub-accordian-row">
                              {buildingData[project.id] && (
                                <table>
                                  <tbody>
                                    {buildingData[project.id].map(
                                      (building) => (
                                        <tr
                                          key={building.id || Math.random()}
                                          className="list-sub-accordian"
                                        >
                                          <td colSpan="4">
                                            <div className="row-wrapper">
                                              <table className="inner-table">
                                                <tbody>
                                                  <tr>
                                                    <td className="babyModel-column">
                                                      <a href="">
                                                        <img
                                                          src={
                                                            building.babyModel
                                                              ? `data:image/png;base64,${building.babyModel}`
                                                              : babyModalReplacement
                                                          }
                                                          className="babyModel-image-list-img"
                                                          alt="baby image"
                                                        />
                                                      </a>
                                                    </td>
                                                    <td className="ForNameRow">
                                                      {building.omniBaseProjectId ? (
                                                        <button className="exploreButton">
                                                          <img
                                                            src={cube}
                                                            alt="explore in 3D"
                                                          />
                                                          <a
                                                            href={`${omnibaseUrl}/projects/${building.omniBaseProjectId}/view?org_uuid=${building.project.omniBaseOrgId}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                          >
                                                            {t("exploreIn3D")}
                                                          </a>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          className="exploreInActive"
                                                          disabled
                                                        >
                                                          <img
                                                            src={cube}
                                                            alt="explore in 3D"
                                                          />
                                                          <a>
                                                            {" "}
                                                            {t("exploreIn3D")}
                                                          </a>
                                                        </button>
                                                      )}
                                                    </td>
                                                    <td className="ForNameRow">
                                                      {building.name}
                                                    </td>
                                                    <td className="ForStatusRow">
                                                      {building.streetName}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
