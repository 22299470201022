import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const deleteAnnotation = async (id) => {
  if (!id) {
    console.error("Invalid annotation ID provided");
    return null;
  }

  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/${id}/delete`;

  try {
    const response = await api.delete(apiUrl, { headers });
    return response;
  } catch (error) {
    console.error("Error deleting the annotation:", error);
    throw error;
  }
};
