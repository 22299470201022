import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import "./editUser.modules.css";
import { toast } from "react-toastify";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import "react-datepicker/dist/react-datepicker.css";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";
import { accLinkReactivation } from "./api/accLinkReactivation";
import { passwordChange } from "./api/passwordChange";
import { fetchUser } from "./api/fetchUser";
import { getOrgList } from "./api/getOrgList";
import { ValidateFields } from "./helperFunctions/validationErrorCheck";
import { getFormattedFormData } from "./helperFunctions/formattedData";
import { UpdateApi } from "./api/updateApi";
import { SuprColumnSettings } from "./components/supAdminEdit/suprmenAdminSpecific";
import { NormalColumnSettings } from "./components/normalEdit/normalUserEdit";
import { PageButtons } from "./components/buttons/PageButtons";
import { getOrgEndDate } from "./api/getOrgEndDate";
import styles from "./editUser.module.css";

const userDetailsDefault = {
  userOrgRoleKey: {},
  organization: {
    id: undefined,
    name: "",
    organizationType: "",
    city: "",
    createDate: "",
    updateDate: "",
  },
  role: {
    id: undefined,
    name: "",
    description: null,
    composite: false,
    clientRole: false,
    containerId: undefined,
  },
  user: {
    id: undefined,
    username: "",
    firstName: "",
    prefix: null,
    lastName: "",
    email: "",
    password: "",
    organization: {
      id: undefined,
      name: "",
      organizationType: "",
      city: "",
      createDate: "",
      updateDate: "",
    },
    createDate: "",
    updateDate: "",
    endDate: "",
    status: "",
    language: "",
    jobTitle: "",
    contactNo: "",
    startDate: "",
  },
  createDate: "",
  updateDate: "",
};

function EditUser({ suprmenAdmin }) {
  const [userDetails, setUserDetails] = useState(userDetailsDefault);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [externaUserEndDate, setExternaUserEndDate] = useState();
  const format1 = "DD-MM-YYYY";
  const sDate = moment(startDate).format(format1);
  const eDate = moment(endDate).format(format1);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const { t } = useTranslation();
  const [userOrg, setUserOrg] = useState();
  const [isConvertionOn, setIsConversionOn] = useState(false);
  const [orgList, setOrgList] = useState();
  const [newOrgId, setNewOrgId] = useState();
  console.log(endDate);
  const getUserDetails = async () => {
    const response = await fetchUser(params.id);
    setUserDetails(response.userDetails);
    setUserOrg(response.userOrg);
  };

  const getOrgDetails = async () => {
    const response = await getOrgList();
    setOrgList(response.data);
  };
  const handleResendActivationLink = async () => {
    if (userDetails.user.status === "INACTIVE") {
      toast.error(`${t("activateUserErrorMessage")}`);
    } else {
      const callLink = await accLinkReactivation({
        userId: userDetails.user.id,
      });
      const linkStatus = callLink.requestStatus;
      if (linkStatus === 200) {
        toast.success(`${t("activateUserSuccessMessage")}`);
      } else {
        toast.error("Please try again");
      }
    }
  };

  const userConvertionToggle = () => {
    setIsConversionOn((prev) => !prev);
  };

  const handlePasswordChange = async (passKeyWord) => {
    if (!passKeyWord?.trim()) return;
    const callChangeReq = await passwordChange({
      userId: userDetails?.user?.id,
      password: passKeyWord,
    });

    if (!callChangeReq.requestStatus === 200) {
      toast.error(callChangeReq.error);
    }
  };

  const updateUser = (value, key) => {
    setUserDetails({
      ...userDetails,
      user: {
        ...userDetails.user,
        [key]: value,
      },
    });
  };

  const updateRole = (value, key) => {
    setUserDetails({
      ...userDetails,
      role: {
        ...userDetails.role,
        [key]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const editedFields = Object.entries(userDetails.user).filter(
      ([key, value]) => {
        const initialValue = userDetailsDefault.user[key];
        return value !== initialValue && value !== "";
      }
    );

    if (editedFields.length > 0) {
      const validationErrors = ValidateFields(
        userDetails,
        startDate,
        endDate,
        t,
        newOrgId,
        isConvertionOn,
        suprmenAdmin
      );

      if (Object.keys(validationErrors).length > 0) {
        setValidationErrors(validationErrors);
        setLoading(false);
        return;
      }
    }

    setValidationErrors({});
    const formData = getFormattedFormData(
      userDetails,
      isConvertionOn,
      newOrgId,
      sDate,
      eDate
    );

    await UpdateApi(
      params.id,
      isConvertionOn,
      formData,
      userDetails,
      handlePasswordChange,
      t,
      navigate
    );

    setLoading(false);
  };

  const handleExternalFunctionSubmit = async () => {
    if (userDetails.user.password && userDetails.user.password.trim() !== "") {
      try {
        await handlePasswordChange(userDetails.user.password);
        navigate("/manageUser");
        toast(`${t("user_Updated")}`);
      } catch (error) {
        toast.error(`${t("checkPassword")}`);
      }
    } else {
      toast.info(`${t("externalUserEditCaution")}`);
    }
  };

  function convertDateFromString(dateString) {
    const momentDate = moment(dateString, "DD-MM-YYYY", true);
    if (momentDate.isValid()) {
      const convertedDate = momentDate.toDate();
      return convertedDate;
    } else {
      console.error(`${t("invalidDateFormat_errorMessage")}`);
      return null;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isConvertionOn && newOrgId) {
        try {
          const response = await getOrgEndDate(newOrgId);
          setExternaUserEndDate(response.data.organization.endDate);
        } catch (error) {
          console.error("Error fetching end date:", error);
        }
      }
    };

    fetchData();
  }, [isConvertionOn, newOrgId]);

  useEffect(() => {
    getUserDetails();
    getOrgDetails();
  }, []);

  useEffect(() => {
    setStartDate(
      convertDateFromString(userDetails.user.startDate || new Date())
    );
    if (isConvertionOn) {
      setEndDate(convertDateFromString(externaUserEndDate));
    } else {
      setEndDate(convertDateFromString(userDetails.user.endDate));
    }
  }, [
    userDetails.user.startDate,
    userDetails.user.endDate,
    externaUserEndDate,
  ]);

  useEffect(() => {
    if (isConvertionOn) {
      updateRole("VIEWER", "name");
    }
  }, [isConvertionOn]);

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img class="sidebar-toggle" src={hamburger}></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>
          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>{t("pageHeader_editUser")}</h1>
                </div>
                <div class="user">
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t("pageHeaderBtn_editUser")}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="user-details-record">
                    <div class="user-details">
                      <div className="form-head user-details">
                        <h2 className="pageHeading">
                          {t("tableHeading_editUser")}
                        </h2>
                      </div>
                      <div class="user-details-content">
                        {suprmenAdmin ? (
                          <SuprColumnSettings
                            handleSubmit={handleSubmit}
                            validationErrors={validationErrors}
                            userDetails={userDetails}
                            updateUser={updateUser}
                            isConvertionOn={isConvertionOn}
                            userOrg={userOrg}
                            t={t}
                            setValidationErrors={setValidationErrors}
                            updateRole={updateRole}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            setNewOrgId={setNewOrgId}
                            orgList={orgList}
                            userConvertionToggle={userConvertionToggle}
                          />
                        ) : (
                          <NormalColumnSettings
                            handleSubmit={handleSubmit}
                            validationErrors={validationErrors}
                            userDetails={userDetails}
                            updateUser={updateUser}
                            userOrg={userOrg}
                            t={t}
                            setValidationErrors={setValidationErrors}
                            updateRole={updateRole}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            setNewOrgId={setNewOrgId}
                            orgList={orgList}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <PageButtons
                    navigate={navigate}
                    t={t}
                    userOrg={userOrg}
                    isConvertionOn={isConvertionOn}
                    handleSubmit={handleSubmit}
                    handleExternalFunctionSubmit={handleExternalFunctionSubmit}
                    suprmenAdmin={suprmenAdmin}
                    handleResendActivationLink={handleResendActivationLink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
