import React from "react";
import { DropdownContent } from "./rowDropDown";
import deleteBtn from "../../../images/trash.png";
import downArrow from "../../../images/down.png";
import blackDelete from "../../../images/blackDelete.png";
import { DropdownFilter } from "../../../utility/dropdownFilters/ColumnFilter";
import { PaginationTool } from "../../../utility/pagination";

const ProjectTable = ({
  project,
  openRows,
  rowData,
  handleRowClick,
  handleEditClick,
  handleSortWrapper,
  sortedColumn,
  isAscending,
  t,
  pageNumbers,
  pageNumber,
  handlePageChange,
  handlePageSize,
  getProjectRoleDisplayText,
  columns,
  selectedColumns,
  onToggleColumn,
  handleResetColumns,
  currentMasterRole,
  masterUserOrgId
}) => {
  const renderSortArrow = (column) => {
    const isSorted = sortedColumn === column;
    return (
      <div className="table-arrow">
        <div className={`arrow-up ${isSorted && isAscending ? "active" : ""}`}>
          &#9650;
        </div>
        <div
          className={`arrow-down ${isSorted && !isAscending ? "active" : ""}`}
        >
          &#9660;
        </div>
      </div>
    );
  };

  return (
    <div className="projectlist-details-table-content">
      <div className="table-content">
        <div className="list">
          <table>
            <thead className="thead-column">
              <tr className="record-row">
                {selectedColumns.projectName && (
                  <th
                    className="ForNameRow opac"
                    onClick={() => handleSortWrapper("projectName")}
                  >
                    {t("projectName_table")}
                    {renderSortArrow("projectName")}
                  </th>
                )}
                {selectedColumns.orgName && (
                  <th
                    className="ForOrgRow opac"
                    onClick={() => handleSortWrapper("orgName")}
                  >
                    {t("organisationName_table")}
                    {renderSortArrow("orgName")}
                  </th>
                )}
                {selectedColumns.role && (
                  <th
                    className="ForRoleRow opac"
                    onClick={() => handleSortWrapper("role")}
                  >
                    {t("projectRole_table")}
                    {renderSortArrow("role")}
                  </th>
                )}
                {selectedColumns.startDate && (
                  <th
                    className="ForDateRow opac"
                    onClick={() => handleSortWrapper("startDate")}
                  >
                    {t("startDate_table")}
                    {renderSortArrow("startDate")}
                  </th>
                )}
                {selectedColumns.endDate && (
                  <th
                    className="ForDateRow opac"
                    onClick={() => handleSortWrapper("endDate")}
                  >
                    {t("endDate_table")}
                    {renderSortArrow("endDate")}
                  </th>
                 
                )}
                 <th></th>
                <DropdownFilter
                  columns={Object.keys(selectedColumns)}
                  selectedColumns={selectedColumns}
                  onToggleColumn={onToggleColumn}
                  handleResetColumns={handleResetColumns}
                />
              </tr>
            </thead>
            <tbody>
              {project.map((pro) => (
                <React.Fragment key={pro.id}>
                  <tr className={openRows[pro.id] ? "clicked-row" : ""}>
                    {selectedColumns.projectName && (
                      <td className="ForNameRow opac">
                        <button
                          className="dropdownBtn"
                          onClick={() => handleRowClick(pro)}
                        >
                          <img
                            className={`downImg ${
                              openRows[pro.id] ? "rotated" : ""
                            }`}
                            src={downArrow}
                            alt="dropdown arrow"
                          />
                        </button>
                        <a
                          className="redirectBtn"
                          onClick={() => handleEditClick(pro)}
                        >
                          {pro.projectName}
                        </a>
                      </td>
                    )}
                    {selectedColumns.orgName && (
                      <td className="ForOrgRow">{pro.organizationName}</td>
                    )}
                    {selectedColumns.role && (
                      <td className="ForRoleRow">
                        {getProjectRoleDisplayText(pro.projectRole)}
                      </td>
                    )}
                    {selectedColumns.startDate && (
                      <td className="ForDateRow">{pro.startDate}</td>
                    )}
                    {selectedColumns.endDate && (
                      <td className="ForDateRow">{pro.endDate}</td>
                    )}
                    <td></td>
                    {selectedColumns.actions && (
                      <td className="action-buttons">
                        <div>
                          <a>
                            <img
                              src={pro.viewable ? deleteBtn : blackDelete}
                            ></img>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                  {openRows[pro.id] && (
                    <tr className="dropDownRow">
                      <td colSpan={9}>
                        <DropdownContent
                          project={project}
                          data={rowData[pro.id]}
                          masterUserOrgId={masterUserOrgId}
                          currentMasterRole={currentMasterRole}
                          
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <PaginationTool
        pageNumbers={pageNumbers}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        t={t}
      />
    </div>
  );
};

export default ProjectTable;
