import { useState, useEffect, useCallback } from "react";
import { fetchBuilding } from "../../api/fetchBuilding";
import { getVisualizationLink } from "../../api/omnibaseLink";
import babyModalReplacement from "../../../../images/default-babyModel.png";
import styles from "./dropdownRow.module.css";
import cube from "../../../../images/cube.png";
import { useTranslation } from "react-i18next";
import { ReportButton } from "../reportButton/reportButton";

export const DropdownRow = ({
  id,
  projectRole,
  orgId,
  currentMasterRole,
  masterUserOrgId,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [omnibaseLink, setOmnibaseLink] = useState();
  const [loading, setLoading] = useState(true);

  const fetchBuildingData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchBuilding(id);
      setData(response?.data || []);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const getOmnibaseLink = async () => {
    const response = await getVisualizationLink();
    setOmnibaseLink(response.data);
  };

  useEffect(() => {
    fetchBuildingData();
  }, [fetchBuildingData]);

  useEffect(() => {
    getOmnibaseLink();
  }, []);

  return (
    <div className={styles.listSubAccordianRow}>
      <table>
        <tbody>
          {loading ? (
            <tr className={styles.listSubAccordian}>
              <td colSpan="9" className={styles.loadingRow}>
                Loading...
              </td>
            </tr>
          ) : data.length === 0 ? (
            <tr>
              <td colSpan="9" className={styles.loadingRow}>
                No data available
              </td>
            </tr>
          ) : (
            data.map((item) => (
              <tr key={item.id}>
                <td colSpan="9">
                  <div className={styles.rowWrapper}>
                    <table className={styles.innerTable}>
                      <tbody>
                        <tr>
                          <td>
                            <a href="">
                              <img
                                src={
                                  item.babyModel
                                    ? `data:image/png;base64,${item.babyModel}`
                                    : babyModalReplacement
                                }
                                className={styles.imgWidth}
                                alt="baby image"
                              />
                            </a>
                          </td>
                          <td>
                            {item.omniBaseProjectId ? (
                              <button className={styles.exploreButton}>
                                <img src={cube} alt="explore in 3D" />
                                <a
                                  href={
                                    item.project?.id === 159
                                      ? `https://bimpartners.iv.navvis.com/?site=3440531431214893&vlon=3.09&vlat=-0.21&fov=100.0&image=1533100006851755`
                                      : `${omnibaseLink}/projects/${item.omniBaseProjectId}/view?org_uuid=${item.project.omniBaseOrgId}`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t("exploreIn3D")}
                                </a>
                              </button>
                            ) : (
                              <button
                                className={styles.exploreInActive}
                                disabled
                              >
                                <img src={cube} alt="explore in 3D" />
                                <a>{t("exploreIn3D")}</a>
                              </button>
                            )}
                          </td>
                          <td>
                            <ReportButton
                              projectStatus={item.project.status}
                              id={item.id}
                              projectId={id}
                              projectRole={projectRole}
                              omniBaseUrlId={item.omniBaseProjectId}
                              orgId={orgId}
                              currentMasterRole={currentMasterRole}
                              masterUserOrgId={masterUserOrgId}
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.streetName}</td>
                          <td>{item.postcode}</td>
                          <td>{item.pandId}</td>
                          <td>{item.city}</td>
                          <td>{item.country}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
