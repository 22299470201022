import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../helper/auth_interceptor";
import { Circles } from "react-loader-spinner";
import "./editProject.modules.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import plus from "../../images/Frame.png";
import home from "../../images/home.png";
import rightArrow from "../../images/right-arrow.png";
import { getUser } from "../../helper/auth_helper";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import SearchWithDropdown from "../searchWithDropdown/searchWithDropdown";
import BulkUpload from "./components/bulkUploader/bulkUploader";
import ProjectAddressDetails from "./components/addressDetails/addressDetails";
import ProjectLeaderDetails from "./components/projectLeaderDetails/projectLeaderDetails";
import ProjectDetails from "./components/projectDetails/projectDetails";
import UserList from "./components/userList/userList";
import AddressModal from "./components/addressModal/addressModal";
import InviteModal2 from "./components/inviteUserModals/inviteModal2";
import BuildAddress from "./components/buildingAddress/buildAddress";
import { getBuildAddresses } from "./api/getBuildings";
import Modal from "react-modal";
import hamburger from "../../images/hamburger.jpg";
import { ProtectedSidebar } from "../sidebar/protectedSidebar";

const defaultProject = {
  description: "",
  endDate: "",
  leaderContactNo: "",
  leaderEmail: "",
  leaderFirstName: "",
  name: "",
  numberOfVHE: 0,
  organizationId: 0,
  organizationName: "",
  projectId: 0,
  projectLeaderId: "",
  startDate: "",
  projectUserList: [],
  selectedId: "",
  leaderLastName: "",
};

function EditProject({ suprmenAdmin, orgAdmin, viewer, regular }) {
  const params = useParams();
  const navigate = useNavigate();
  const [roleInput, setRoleInput] = useState("PROJECT_VIEWER");
  const [backendAddress, setBackendAddress] = useState([]);
  const { t } = useTranslation();

  const [project, setProject] = useState(defaultProject);
  const [userList, setUserList] = useState([]);
  const [buildFlag, setBuildFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const projectId = params.id;
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const userSearchInput = useRef("");
  console.log(project);
  const getSearchApiUrl = (valueToSearch) =>
    `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/orgUser/search?query=${valueToSearch}`;

  const handleSubmit = async () => {
    const user = await getUser();
    setValidationErrors({});

    const errors = {};
    if (!project.name?.trim()) {
      errors.name = `${t("tabOneProjectName_projectEditProject")}`;
    }

    if (!project.endDate) {
      errors.endDate = `${t("endDate")} ${t("required_errorMessage")}`;
    } else if (moment(project.endDate).isSameOrBefore(project.startDate)) {
      errors.endDate = `${t("preferredDileverDate_errorMessage")}`;
    }

    if (!project.leaderFirstName?.trim()) {
      errors.leaderFirstName = `${t("firstName")} ${t(
        "required_errorMessage"
      )}`;
    }
    if (!project.leaderLastName?.trim()) {
      errors.leaderLastName = `${t("lastName")} ${t("required_errorMessage")}`;
    }
    if (!project.leaderEmail?.trim()) {
      errors.leaderEmail = `${t("Email Address")} ${t(
        "required_errorMessage"
      )}`;
    }
    if (!project.leaderContactNo?.trim()) {
      errors.leaderContactNo = `${t("phoneNo_errorMessage")}`;
    } else if (!/^[\d+()-]*$/.test(project.leaderContactNo)) {
      errors.leaderContactNo = `${t("phoneNo_errorMessage")}`;
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setLoading(true);

    const requestBody = {
      leaderContactNo: project.leaderContactNo,
      endDate: project.endDate,
      leaderEmail: project.leaderEmail,
      description: project.description,
      leaderFirstName: project.leaderFirstName,
      name: project.name,
      startDate: project.startDate,
      leaderLastName: project.leaderLastName,
      selectedId: orgAdmin
        ? project.organizationId
        : project.selectedId || project.organizationId,
    };

    const orgId = project.selectedId || project.organizationId;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/${projectId}?orgId=${orgId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.put(apiUrl, requestBody, {
        headers,
      });
      if (response) {
        toast(`${t("project_Updated")}`);
        navigate("/projectlist");
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast.error(`${t("notAuthorized_errorMessage")}`);
      } else if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      } else {
        toast.error(`${t("errorUpdatingProject_errorMessage")}`);
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const inviteUser = async (userCreds) => {
    setLoading(true);
    const requestBody = {
      email: userCreds.email,
      projectRoleType: roleInput,
      firstName: userCreds.firstName,
      lastName: userCreds.lastName,
    };
    const user = await getUser();
    const inviteApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/user/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.post(inviteApi, requestBody, {
        headers,
      });
      if (response) {
        FetchProject();
        console.log("user created!!");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error(`${"userAlreadyExists_errorMessage"}`);
      }
      if (error.response && error.response.status === 403) {
        toast.error(`${"userAlreadyExists_errorMessage"}`);
      } else if (error.response && error.response.data) {
        const errorMessages = Object.values(error.response.data).join("\n ");
        toast.error(errorMessages);
      } else {
        toast.error(`${t("errorInviting_errorMessage")}`);
      }
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  function convertDateFromString(dateString) {
    const momentDate = moment(dateString, "DD-MM-YYYY", true);
    if (!momentDate.isValid()) {
      console.error(`${t("invalidDateFormat_errorMessage")}`);
      return null;
    }

    const convertedDate = momentDate.toDate();
    return convertedDate;
  }

  const deleteUser = async (id) => {
    const user = await getUser();
    const inviteApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/user/${params.id}/updateStatus`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    const reqBody = {
      userId: id,
    };

    try {
      const response = await api.put(inviteApi, reqBody, {
        headers,
      });

      if (response) {
        FetchProject();
        toast.success(`${t("userDeleted")}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAddresses = async (
    valueToSearch,
    currentPage,
    sortColumn,
    sortOrder
  ) => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/address/filter/${params.id}?pageNumber=${currentPage}&pageSize=10&sortBy=${sortColumn}&sortOrder=${sortOrder}`;
    const requestBody = {
      searchText: valueToSearch,
    };
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.post(getApi, requestBody, { headers });
      setBackendAddress(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const FetchProject = async () => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/${params.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      const response = await api.get(apiUrl, {
        headers,
      });

      setProject(response.data);
    } catch (error) {
      if (error.response) {
        if (
          (viewer || regular) &&
          (error.response.status === 404 || error.response.status === 403)
        ) {
          console.error("Error fetching data:", error);
          toast.error(`${t("accessDenialProject_errorMessage")}`);
          navigate("/projectlist");
        } else {
          toast.error(`${t("accessDenialProject_errorMessage")}`);
        }
      } else if (error.request) {
        console.error("Error fetching data:", error);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleRoleInput = (event) => {
    setRoleInput(event.target.value);
  };

  const updateUserInList = (updatedUser, index) => {
    const updatedUserList = [...userList];
    updatedUserList[index] = { ...updatedUser, isExternalUser: true };
    setUserList(updatedUserList);
  };

  const addUserToList = (user) => {
    inviteUser(user);
  };

  const handleDownload = async () => {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    fetch(
      `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/excel/download`,
      { headers }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "project_template.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading Excel file:", error));
  };

  const getBuildingCount = async () => {
    const { data } = await getBuildAddresses(projectId);
    setBuildFlag(data?.data?.length > 0);
    console.log(buildFlag);
  };

  useEffect(() => {
    getAddresses("", currentPage);
    FetchProject();
  }, [currentPage]);

  useEffect(() => {
    getBuildingCount();
    setStartDate(convertDateFromString(project.startDate));
    setEndDate(convertDateFromString(project.endDate));
  }, [project.startDate, project.endDate]);

  return (
    <div className={`project-creation-container ${loading ? "blur" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <Circles type="TailSpin" color="#FF6B00" height={150} width={100} />
        </div>
      )}
      <div class="main-wrap">
        <div className="quote-body">
          <input id="slide-sidebar" type="checkbox" role="button" />
          <label for="slide-sidebar">
            <span>
              <i class="fa fa-bars">
                <img class="sidebar-toggle" alt="sidebar" src={hamburger}></img>
              </i>
            </span>
          </label>
          <div class="sidebar">
            <div class="sidebar-content">
              <div>
                <ProtectedSidebar />
              </div>
            </div>
          </div>
          <div class="portfolio">
            <div class="main-content">
              <div class="main-header-content">
                <div className="pageHeader">
                  <h1>
                    {t("pageHeader_editProject")} {params.id}
                  </h1>
                </div>
                <div class="user">
                  <button
                    className="btn btn-light quoteBtns rqstbtn "
                    type="button"
                  >
                    <img src={home} alt="home icon" />
                    <img src={rightArrow} alt="right arrow icon" />
                    <span>{t("tabOneHeaderBtn_projectEditProject")}</span>
                  </button>
                </div>
              </div>
              <div className="page-body">
                <div className="formDiv">
                  <div class="project-details-record">
                    <ProjectDetails
                      isOrgAdmin={orgAdmin}
                      isSuprAdmin={suprmenAdmin}
                      projectInfo={project}
                      setProject={(project) => setProject(project)}
                      onSelectedIdChange={(id) => setSelectedId(id)}
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div class="project-leaderdetails-record">
                    <ProjectLeaderDetails
                      setProject={(project) => setProject(project)}
                      projectInfo={project}
                      isOrgAdmin={orgAdmin}
                      isSuprAdmin={suprmenAdmin}
                      validationErrors={validationErrors}
                    />
                  </div>
                    <div class="bottom-button">
                      {!project.readOnly ? (
                        <button
                          type="submit"
                          className="btn-save"
                          onClick={() => handleSubmit()}
                        >
                          <span>{t("saveBtn")}</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <BuildAddress
                      id={projectId}
                      orgId={project.organizationId}
                      suprmenAdmin={suprmenAdmin}
                      project={project}
                      getBuildingCount={getBuildingCount}
                    />

                    {buildFlag ? (
                      <div class="edit-modal-buttons">
                        <div>
                          {!project.readOnly ? (
                            <button
                              className="btn btn-success bulkUploadBtn"
                              onClick={() => handleDownload()}
                            >
                              <span>{t("tabThreeHeader_editProject")}</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-success bulkUploadBtn"
                              disabled
                            >
                              <span>{t("tabThreeHeader_editProject")}</span>
                            </button>
                          )}
                        </div>
                        <div>
                          <BulkUpload
                            projectInfo={project}
                            projectId={projectId}
                            getAddresses={getAddresses}
                          />
                        </div>
                        <div>
                          <AddressModal
                            projectId={projectId}
                            projectInfo={project}
                            fetchProject={FetchProject}
                            getAddresses={getAddresses}
                          />
                        </div>
                      </div>
                    ) : null}

                    {buildFlag ? (
                      <ProjectAddressDetails
                        projectInfo={project}
                        setProject={(project) => setProject(project)}
                        backendAddress={backendAddress}
                        id={projectId}
                        getAddresses={getAddresses}
                      />
                    ) : null}

                    {!project.readOnly ? (
                      <div class="invite-record">
                        <div className="invite-content">
                          <div>
                            <h2 className="pageHeading">
                              {t("tabFiveHeader_editProject")}
                            </h2>
                          </div>
                          <div class="invite-content-buttons">
                            <div className="d-flex inviteForm" role="search">
                              <SearchWithDropdown
                                searchInputValue={userSearchInput}
                                onSelectItem={(item) => {
                                  addUserToList({
                                    ...item,
                                    isExternalUser: false,
                                  });
                                }}
                                getApiUrl={getSearchApiUrl}
                              />
                            </div>
                            <div className="dropdown">
                              <select className=" " onChange={handleRoleInput}>
                                <option>
                                  {t("selectRole_editProjectTable")}
                                </option>
                                <option value="PROJECT_USER">
                                  {t("user_editProjectTable")}
                                </option>
                                <option value="PROJECT_VIEWER">
                                  {t("viewer_editProjectTable")}
                                </option>
                              </select>
                            </div>
                            <div>
                              <button
                                className="btn btn-success invite-button"
                                type="submit"
                                onClick={() => {
                                  setUserModalIsOpen(true);
                                }}
                              >
                                <span>{t("tabFiveInviteBtn_editProject")}</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <Modal
                          isOpen={userModalIsOpen}
                          onRequestClose={() => setUserModalIsOpen(false)}
                          style={{
                            content: {
                              width: "40%",
                              height: "40%",
                              margin: "auto",
                            },
                          }}
                        >
                          <div className="modal-content">
                            <div class="modal-header">
                              <div>
                                <h2>{t("editPageHeader_addressDetails")}</h2>
                              </div>
                              <div>
                                <button
                                  className="closeBtn"
                                  onClick={() => setUserModalIsOpen(false)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="25px"
                                  >
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <InviteModal2
                            id={params.id}
                            fetchProject={FetchProject}
                            closeModal={() => setUserModalIsOpen(false)}
                            searchInputValue={userSearchInput.current}
                          />
                        </Modal>

                        <div class="invite-userlist-record">
                          <UserList
                            userList={project?.projectUserList || []}
                            deleteFromUserList={deleteUser}
                            updateUserInList={updateUserInList}
                            id={params.id}
                            fetchProject={FetchProject}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProject;
