import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const GetVisualizationLink = async () => {
  const user = await getUser();
  const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/visualization-management/api/get-omnibase-link`;
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  try {
    const response = await api.get(getApi, { headers });
    return {
      link: response.data,
    };
  } catch (error) {
    console.log("Error:", error);
  }
};
