import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const fetchTableData = async ({
  requestBody,
  currentPage,
  selectedPageSize,
  sortColumn,
  sortOrder,
}) => {
  const user = await getUser();
  let apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/filter?pageNumber=${currentPage}&pageSize=${selectedPageSize}`;
  if (sortColumn && sortOrder) {
    apiUrl += `&sortBy=${sortColumn}&sortOrder=${sortOrder}`;
  }

  const headers = { Authorization: `Bearer ${user.access_token}` };

  try {
    const response = await api.post(apiUrl, requestBody, { headers });

    if (response && response.data) {
      return {
        data: response.data.data || [],
        totalPages: response.data.totalPages || 0,
      };
    }

    return { data: [], totalPages: 0 };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: [], totalPages: 0 };
  }
};
