import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";
import { toast } from "react-toastify";

export const UpdateApi = async (
  id,
  isConvertionOn,
  formData,
  userDetails,
  handlePasswordChange,
  t,
  navigate
) => {
  const user = await getUser();
  const normalApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/${id}`;
  const convertionApi = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/convert-external/${id}`;
  const apiUrl = isConvertionOn ? convertionApi : normalApi;
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  try {
    const response = await api.put(apiUrl, formData, {
      headers,
    });
    if (userDetails.user.password !== ("" || undefined)) {
      await handlePasswordChange(userDetails.user.password);
    }

    if (response) {
      toast(`${t("user_Updated")}`);
      navigate("/manageUser");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessages = Object.values(error.response.data).join("\n ");
      toast.error(errorMessages);
    } else {
      toast.error(`${t("checkPassword")}`);
    }
    console.error("Error fetching data:", error);
  }
};
