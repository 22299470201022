import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";
import FileSaver from "file-saver";
import moment from "moment/moment";

export async function exportData({
  id,
  sortColumn,
  sortOrder,
  exportBody,
  fileName,
}) {
  const dateAndTime = moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
  const user = await getUser();

  try {
    
    const response = await api.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/export-annotations-list/${id}?sortBy=${ sortColumn}&sortOrder=${sortOrder}`,
      exportBody,
      {
        headers: {
          authorization: `Bearer ${user.access_token}`,
        },
        responseType: "blob",
      }
    );

    const formattedFileName = `${fileName}_${moment(new Date()).format(
      "MM/DD/YYYY"
    )}_sorted_${dateAndTime}.xlsx`;

    FileSaver.saveAs(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      formattedFileName
    );
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
