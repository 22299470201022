import React  from "react";
import styles from "./DataTable.module.css";
import { PaginationTool } from "../../../../utility/pagination";
import { TableHead } from "./TableHead";
import { TableBody } from "./TableBody";
import { useColumnVisibility } from "./columnConfig.jsx";

export const DataTable = ({
  handlePageSize,
  handlePageChange,
  totalPageNumbers,
  pageNumber,
  tableData,
  fetchTableData,
  currentToggle,
  summaryData,
  conditieMetingToggle,
}) => {
  const { selectedColumns, handleToggleColumn, handleResetColumns } =
    useColumnVisibility();
  

  return (
    <div className={styles.projectlistDetailsTableContent}>
      <div className={styles.tableContent}>
        <div className={styles.list}>
        <div className={`${styles.list} ${conditieMetingToggle ? "scroll-enabled" : ""}`}>
        <table>
            <TableHead
              selectedColumns={selectedColumns}
              fetchTableData={fetchTableData}
              conditieMetingToggle={conditieMetingToggle}
              handleToggleColumn={handleToggleColumn}
              handleResetColumns={handleResetColumns}
            />
            <TableBody
              selectedColumns={selectedColumns}
              conditieMetingToggle={conditieMetingToggle}
              tableData={tableData}
              currentToggle={currentToggle}
              summaryData={summaryData}
            />
          </table>
          </div>
        </div>
      </div>
      <PaginationTool
        pageNumbers={totalPageNumbers}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
      />
    </div>
  );
};
