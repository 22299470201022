import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export async function fetchJsonReportData(
  id,
  selectedPageSize,
  currentPage,
  requestBody,
  sortColumn,
  sortOrder
) {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  let apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/search/${id}?pageNumber=${currentPage}&pageSize=${selectedPageSize}`;

  if (sortColumn && sortOrder) {
    apiUrl += `&sortBy=${sortColumn}&sortOrder=${sortOrder}`;
  }

  try {
    const response = await api.post(apiUrl, requestBody, {
      headers,
    });
    return {
      apiTableData: response.data,
    };
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
