import React, { useState, useContext, useEffect } from "react";
import styles from "./addressListPage.module.css";
import checkboxSelection from "../../../../images/checkbox-selection-dropdown.png";
import pdfIcon from "../../../../images/pdfFileIcon.png";
import dullPdf from "../../../../images/dullPdf.png";
import { exportAddressReport } from "../api/downloadReports";
import { PageHeader } from "../../../../helperComponents/pageHeader/PageHeader";
import { DataTable } from "./listingTable/listingTable";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProtectedSidebar } from "../../../sidebar/protectedSidebar";
import hamburger from "../../../../images/hamburger.jpg";
import "react-toastify/dist/ReactToastify.css";
import collapsable from "../../../../images/column-selection.png";
import { handleSort } from "../handleSort";
import { callReportType } from "../api/reportType";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../../../helperComponents/sideBar/context/sideContext";
import { ProtectedSide } from "../../../../helperComponents/sideBar/protectedSide";
import { TableFilter } from "../../../../helperComponents/TableFilter/TableFilters";
import { searchFilter } from "../api/searchFilterApi";
import { getColumnPreferences, saveColumnPreferences } from "../api/prefrences";
import lockedFile from "../../../../images/LockedFile.png";


export const AddressListPage = ({ suprmenAdmin }) => {
  const params = useParams();
  const buildingId = params.id;
  const { t } = useTranslation();
  const { isCollapsed } = useContext(SidebarContext);
  const pageName = t("addressList");
  const returnPage = t("myProjects_home");
  const [addressReportList, setAddressReportList] = useState([]);
  const [rowHeadData, setRowHeadData] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const showFilters = {
    search: true,
    status: false,
    role: false,
    date: false,
    clear: false,
    export: false,
    createNew: false,
  };

  const loadSavedPreferences = async () => {
    const savedPreferences = await getColumnPreferences();
    if (savedPreferences?.selectedColumns) {
      const columnVisibility = {};
      savedPreferences.selectedColumns.split(",").forEach((column) => {
        columnVisibility[column.trim()] = true;
      });
      setVisibleColumns(columnVisibility);
    }
  };

  const savePreferences = async (preferences) => {
    const visibleColumns = Object.keys(preferences)
      .filter((column) => preferences[column])
      .join(", ");
    const payload = {
      page: "ADDRESS_REPORT",
      selectedColumns: visibleColumns,
    };
    await saveColumnPreferences(payload); // Save preferences to backend
  };

  const handleSortWrapper = async (column, page) => {
    const sorting = await handleSort(
      column,
      page,
      buildingId,
      sortedColumn,
      isAscending,
      setSortedColumn,
      setIsAscending,
      searchTerm
    );
    setAddressReportList(sorting.searchData);
  };

  const handleSearch = async (searchItem) => {
    const searchData = await searchFilter(buildingId, searchItem);
    setAddressReportList(searchData.searchData);
    setSearchTerm(searchItem);
  };

  const handleButtonClick = (reportId, fileName, fileId) => {
    if (fileId) {
      exportAddressReport(reportId, fileName);
    } else {
      toast.error(`${t("fileDownload_errorMessage")}`);
    }
  };

  const toggleColumnVisibility = (columnKey) => {
    const updatedColumns = {
      ...visibleColumns,
      [columnKey]: !visibleColumns[columnKey],
    };
    setVisibleColumns(updatedColumns);
    savePreferences(updatedColumns);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const ReportTypes = async () => {
    const reportData = await callReportType();

    const initialVisibility = {};
    reportData.data.forEach((row) => {
      initialVisibility[row.title] = true;
    });

    setRowHeadData(reportData.data);
    if (!localStorage.getItem("columnVisibility")) {
      setVisibleColumns(initialVisibility);
    }
  };

  const resetColumnVisibility = () => {
    const resetColumns = {};
    rowHeadData.forEach((column) => {
      resetColumns[column.title] = true;
    });
    setVisibleColumns(resetColumns);
    savePreferences(resetColumns);
  };

  const dynamicColumns = rowHeadData
    .filter((row) => visibleColumns[row.title])
    .map((row) => ({
      key: row.title,
      header: row.title,
      visible: true,
      customClass: row.title,
    }));

  const columns = [
    {
      key: "streetHouseNo",
      header: `${t("houseNo")}`,
      visible: true,
      customClass: "streetHouseNo",
    },
    ...dynamicColumns,
    {
      key: "column selection",
      header: (
        <button class="selection-button">
        <img
          src={collapsable}
          alt="Column Selection"
          className={styles.headerImage}
          onClick={toggleDropdown}
        />
        </button>
      ),
      visible: true,
      customClass: "collapsableColumn",
    },
  ];

  const getColumnContent = (reportList, columnTitle) => {
    const report = reportList?.find((r) => r.reportType === columnTitle);
    if (report && report.fileId) {
      return (
        <img
          src={pdfIcon}
          onClick={() => handleButtonClick(report.reportId, report.fileName, report.fileId)}
          alt="pdf"
          className={styles.pointer}
        />
      );
    }else if (report && !report.fileId){
      return(<>
        <img src={pdfIcon} alt="pdf" className="floor-overlay"/>
          <img
            src={lockedFile}
            onClick={() =>
              handleButtonClick(report.reportId, report.fileName, report.fileId)
            }
            alt="blockedPdf"
            className="overlay-image"
          />
        </>)
    } else {
      return <img src={dullPdf} alt="dull pdf" />;
    }
  };

  const data = addressReportList?.map((address) => {
    const rowData = {
      streetHouseNo: `${address.streetName} ${address.houseNo}`,
    };

    rowHeadData?.forEach((column) => {
      if (visibleColumns[column.title]) {
        rowData[column.title] = getColumnContent(
          address.reportList,
          column.title
        );
      }
    });

    return rowData;
  });

  const getClassForColumn = (title) => {
    switch (title) {
      case "NEN2580":
        return styles.nen2580Class;
      case "WWS":
        return styles.wwsClass;
      case "EPW":
        return styles.epwClass;
      case "Floor Plan":
        return styles.floorClassName;

      default:
        return styles.defaultClass;
    }
  };

  useEffect(() => {
    handleSearch();
    ReportTypes();
    loadSavedPreferences();
  }, []);

  return (
    <div className="main-wrap">
       <div className="quote-body">
        <input id="slide-sidebar" type="checkbox" role="button" />
        <label for="slide-sidebar">
          <span>
            <i class="fa fa-bars">
              <img
                class="sidebar-toggle"
                alt="hamburger button"
                src={hamburger}
              ></img>
            </i>
          </span>
        </label>
        <div class="sidebar">
          <div class="sidebar-content">
            <div>
              <ProtectedSidebar />
            </div>
          </div>
        </div>
      <div
        className="portfolio">
        <PageHeader pageName={pageName} returnPage={returnPage} />
        <div className={styles.dataTable}>
          <div className={styles.maintable}>
            <h2 className={styles.dataTableHeading}>{t("allAddresses")}</h2>

            <TableFilter
              showFilters={showFilters}
              handleSearch={handleSearch}
            />

            <div
              style={{
                position: "relative",
                backgroundColor: "white",
              }}
            >
            {dropdownOpen && (
              <div className={styles.columnDropdown}>
                {/* Container for overlapping sections */}
                <div className="selection-container">
                  <div className="selection-container-content">
                    <label>My Column Settings</label>
                  </div>

                  {/* Main content of the dropdown */}
                  <ul class="addressListColumn">
                    <div className="selection">
                      <button
                        onClick={resetColumnVisibility}
                        className="reset-selection"
                      >
                        Reset to Default
                      </button>
                    </div>

                    {/* Render columns as list items */}
                    <div className="selection-li-content">
                      {rowHeadData.map((column) => (
                        <li key={column.title}>
                          <label>
                          <img
                                src={checkboxSelection}
                                alt="Checkbox Selection Dropdown Image"
                          />
                            <input
                              type="checkbox"
                              className={getClassForColumn(column.title)}
                              checked={visibleColumns[column.title]}
                              onChange={() => toggleColumnVisibility(column.title)}
                            />
                            {column.title}
                          </label>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </div>
)}

            </div>
            <DataTable
              columns={columns}
              data={data}
              handleSortWrapper={handleSortWrapper}
              sortedColumn={sortedColumn}
              isAscending={isAscending}
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
