import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export async function fetchColumnPreference() {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const page = "ANNOTATIONS";
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/${page}`;

  try {
    const response = await api.get(apiUrl, {
      headers,
    });
    return {
      apiColumnData: response.data,
    };
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
