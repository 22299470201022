import { useEffect, useState } from "react";
import { fetchColumnPreference } from "../../Api/fetchColumnPreference";
import { postColumnPreference } from "../../Api/postColumnPreferences";

export const useColumnVisibility = () => {
  const [selectedColumns, setSelectedColumns] = useState({
    elementName: true,
    detection: true,
    viewIn3D: true,
    afwijking: true,
    toelichting: true,
    locatieDak: true,
    locatieBuitenwand: true,
    intensiteit: true,
    omvang: true,
    risicoaspect: true,
    prioriteit: true,
    type: true,
    location: true,
    length: true,
    area: true,
    quantity: true,
    lengthTotal: true,
    grossTotal: true,
    netTotal: true,
  });
  const initializeSelectedColumns = async () => {
    try {
      const initializedColumns = await fetchColumnPreference();

      const savedSettings =
        initializedColumns.apiColumnData.selectedColumns.split(",");
      const defaultColumns = {
        elementName: true,
        detection: true,
        viewIn3D: true,
        afwijking: true,
        toelichting: true,
        locatieDak: true,
        locatieBuitenwand: true,
        intensiteit: true,
        omvang: true,
        risicoaspect: true,
        prioriteit: true,
        type: true,
        location: true,
        length: true,
        area: true,
        quantity: true,
        lengthTotal: true,
        grossTotal: true,
        netTotal: true,
      };

      if (savedSettings.length > 0) {
        const updatedColumns = { ...defaultColumns };
        Object.keys(defaultColumns).forEach((column) => {
          updatedColumns[column] = savedSettings.includes(column);
        });
        return updatedColumns;
      }
      return defaultColumns;
    } catch (error) {
      console.error("Error fetching column visibility:", error);
      return {
        elementName: true,
        detection: true,
        viewIn3D: true,
        afwijking: true,
        toelichting: true,
        locatieDak: true,
        locatieBuitenwand: true,
        intensiteit: true,
        omvang: true,
        risicoaspect: true,
        prioriteit: true,
        type: true,
        location: true,
        length: true,
        area: true,
        quantity: true,
        lengthTotal: true,
        grossTotal: true,
        netTotal: true,
      };
    }
  };

  useEffect(() => {
    const fetchInitialColumns = async () => {
      const initialColumns = await initializeSelectedColumns();
      setSelectedColumns(initialColumns);
    };

    fetchInitialColumns();
  }, []);

  const saveColumnVisibility = async (updatedColumns) => {
    await postColumnPreference(updatedColumns);
  };

  const handleResetColumns = () => {
    const defaultColumns = {
      elementName: true,
      detection: true,
      viewIn3D: true,
      afwijking: true,
      toelichting: true,
      locatieDak: true,
      locatieBuitenwand: true,
      intensiteit: true,
      omvang: true,
      risicoaspect: true,
      prioriteit: true,
      type: true,
      location: true,
      length: true,
      area: true,
      quantity: true,
      lengthTotal: true,
      grossTotal: true,
      netTotal: true,
    };

    setSelectedColumns(defaultColumns);
    saveColumnVisibility(defaultColumns);
  };

  const handleToggleColumn = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = {
        ...prevSelectedColumns,
        [column]: !prevSelectedColumns[column],
      };
      saveColumnVisibility(updatedColumns);
      return updatedColumns;
    });
  };
  return {
    selectedColumns,
    handleToggleColumn,
    handleResetColumns,
  };
};
