import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_PRODUCTION_SCRIPT
) {
  const script = document.createElement("script");
  script.defer = true;
  script.src = process.env.REACT_APP_PRODUCTION_SCRIPT;
  script.id = "pianjs";
  script.setAttribute(
    "data-code",
    process.env.REACT_APP_PRODUCTION_SCRIPT_DATA_CODE
  );
  document.head.appendChild(script);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
