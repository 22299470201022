import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const fetchUser = async (id) => {
  const user = await getUser();
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/${id}`;
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  try {
    const response = await api.get(apiUrl, {
      headers,
    });

    return {
      userDetails: response.data,
      userOrg: response?.data?.organization,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
