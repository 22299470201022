export const PageButtons = ({
  navigate,
  t,
  userOrg,
  isConvertionOn,
  handleSubmit,
  handleExternalFunctionSubmit,
  suprmenAdmin,
  handleResendActivationLink,
}) => {
  return (
    <div class="bottom-button">
      <button className="btn-back" onClick={() => navigate("/manageUser")}>
        <span>{t("backBtn")}</span>
      </button>
      <button
        type="submit"
        onClick={
          userOrg !== null || (userOrg === null && isConvertionOn)
            ? handleSubmit
            : handleExternalFunctionSubmit
        }
        className="btn-save"
      >
        <span>{t("saveBtn")}</span>
      </button>
      {suprmenAdmin ? (
        <button className="btn-resend" onClick={handleResendActivationLink}>
          <span>{t("resendLinkBtn")}</span>
        </button>
      ) : null}
    </div>
  );
};
