import React, { useState, useEffect } from "react";
import styles from "./DataTable.module.css";
import viewLink from "../../../../../src/images/view-link.png";
import viewLinkRedirect from "../../../../../src/images/view-linkRedirect.png";
import { GetVisualizationLink } from "../../Api/fetchOmnibaseLink";
import { FormatNumberEuStandard } from "../../../commonUtility/euStandardFormatCovert";
import { TableTotal } from "./TableTotal";
import aiImage from "../../../../images/ai_annotation_logo.png";

export const TableBody = ({
  selectedColumns,
  tableData,
  conditieMetingToggle,
  currentToggle,
  summaryData,
}) => {
  const [omnibaseLink, setOmnibaseLink] = useState();

  const getOmniBaseLink = async () => {
    const GetLink = await GetVisualizationLink();
    setOmnibaseLink(GetLink.link);
  };

  useEffect(() => {
    getOmniBaseLink();
  }, []);
  return (
    <tbody>
      {tableData && tableData.length > 0 ? (
        tableData.map((item) => (
          <tr>
            {selectedColumns.elementName && (
              <td
                className={`${styles.elementRow} ${styles.rightColumn}`}
                data-full-text={item.elementName}
              >
                {item.elementName}
              </td>
            )}
            {selectedColumns.detection && (
              <td className={`${styles.detectionRow} ${styles.rightColumn}`}>
                {item.aiGenerated && (
                  <img className={styles.botImage} src={aiImage} />
                )}
              </td>
            )}

            {selectedColumns.viewIn3D && (
              <td className={`${styles.viewRow} ${styles.rightColumn}`}>
                <a
                  href={`${omnibaseLink}/projects/${item.omniBaseProjectId}/view?annotation=${item.annotationUuid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={viewLink} alt="view Link" />
                  <img src={viewLinkRedirect} alt="view Link Redirect" />
                </a>
              </td>
            )}
            {conditieMetingToggle ? (
              <>
                {selectedColumns.afwijking && (
                  <td
                    className={`${styles.deviationRow} ${styles.rightColumn}`}
                  >
                    {item.afwijking}
                  </td>
                )}
                {selectedColumns.toelichting && (
                  <td
                    className={`${styles.explanationRow} ${styles.rightColumn}`}
                    data-full-text={item.toelichting}
                  >
                    {item.toelichting}
                  </td>
                )}
                {selectedColumns.locatieDak && (
                  <td
                    className={`${styles.locationRoofRow} ${styles.rightColumn}`}
                  >
                    {item.locatie_dak}
                  </td>
                )}
                {selectedColumns.locatieBuitenwand && (
                  <td className={`${styles.facadeRow} ${styles.rightColumn}`}>
                    {item.locatie_buitenwand}
                  </td>
                )}
                {selectedColumns.intensiteit && (
                  <td
                    className={`${styles.intensityRow} ${styles.rightColumn}`}
                  >
                    {item.intensiteit}
                  </td>
                )}
                {selectedColumns.omvang && (
                  <td className={`${styles.extentRow} ${styles.rightColumn}`}>
                    {item.omvang}
                  </td>
                )}
                {selectedColumns.risicoaspect && (
                  <td className={`${styles.aspectRow} ${styles.rightColumn}`}>
                    {item.risicoaspect}
                  </td>
                )}
                {selectedColumns.prioriteit && (
                  <td className={`${styles.priorityRow} ${styles.rightColumn}`}>
                    {item.prioriteit}
                  </td>
                )}
              </>
            ) : null}
            {selectedColumns.type && (
              <td className={`${styles.typeRow} ${styles.rightColumn}`}>
                {item.type}
              </td>
            )}
            {selectedColumns.location && (
              <td className={`${styles.locationRow} ${styles.rightColumn}`}>
                {item.location}
              </td>
            )}

            {selectedColumns.length && (
              <td
                className={`${styles.lengthRow} ${styles.rightColumn} ${styles.extraWidth} `}
              >
                {FormatNumberEuStandard(item.length)}
              </td>
            )}
            {selectedColumns.area && (
              <td
                className={`${styles.areaRow} ${styles.rightColumn}  ${styles.extraWidth}`}
              >
                {FormatNumberEuStandard(item.area)}
              </td>
            )}
            {selectedColumns.quantity && (
              <td
                className={`${styles.quantityRow} ${styles.rightColumn}  ${styles.extraWidth}`}
              >
                {item.quantity}
              </td>
            )}

            {selectedColumns.lengthTotal && (
              <td
                className={`${styles.lengthTotalRow} ${styles.rightColumn}  ${styles.extraWidth}`}
              >
                {FormatNumberEuStandard(item.lengthTotal)}
              </td>
            )}
            {selectedColumns.grossTotal && (
              <td
                className={`${styles.grossRow} ${styles.rightColumn}  ${styles.extraWidth}`}
              >
                {FormatNumberEuStandard(item.grossTotal)}
              </td>
            )}
            {selectedColumns.netTotal && (
              <td
                className={`${styles.netRow} ${styles.rightColumn}  ${styles.extraWidth}`}
              >
                {FormatNumberEuStandard(item.netTotal)}
              </td>
            )}

            <td className={`${styles.extraWidth}`}></td>
            <td></td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5">No data available</td>
        </tr>
      )}
      <TableTotal
        currentToggle={currentToggle}
        summaryData={summaryData}
        selectedColumns={selectedColumns}
        conditieMetingToggle={conditieMetingToggle}
      />
    </tbody>
  );
};
