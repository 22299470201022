import React, { useState, useEffect, useRef, useCallback } from "react";
import { getUser, login, logout } from "../../helper/auth_helper";
import "./sidebar.modules.css";
import { useTranslation } from "react-i18next";
import project from "../../images/project.png";
import users from "../../images/Users2.png";
import quote from "../../images/quotes.png";
import dashboard from "../../images/dashboard.png";
import reports from "../../images/Reports.jpg";
import userslogo from "../../images/users-logo.png";
import reportlistlogo from "../../images/reportalistPageLogo.png";
import username from "../../images/username.png";
import logouts from "../../images/logout.png";
import jwt_decode from "jwt-decode";
import api from "../../helper/auth_interceptor";
import { useNavigate } from "react-router-dom";
import hamburger from "../../images/hamburger.jpg";

const Sidebar = ({ suprmenAdmin, orgAdmin }) => {
  //state and constants declaration
  const collapsed = useRef(false);
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [activeHref, setActiveHref] = useState("/projectlist");
  const [showQuote, setShowQuote] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // New state for sidebar visibility
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState();
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  //useEffecto check path location logged in organisation type
  useEffect(() => {
    const currentUrl = window.location.pathname;

    setActiveHref(currentUrl);
    getUser().then(async (user) => {
      if (user) {
        const token = user.access_token;
        setUser(user);
        const decodedToken = jwt_decode(token);
        const orgId = decodedToken.orgId;
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/${orgId}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        try {
          const response = await api.get(apiUrl, { headers });
          if (
            response?.data?.organizationType === "SUPRMEN" ||
            response?.data?.organizationType === "DRONEOPERATOR" ||
            response?.data?.organizationType === "INSPECTION"
          ) {
            setShowQuote(true);
            setOrgName(response.data.name);
            if (
              response?.data?.organizationType === "DRONEOPERATOR" &&
              (currentUrl === "/quotecreation" ||
                currentUrl.startsWith("/editQuote/") ||
                currentUrl.startsWith("/externalSp/"))
            ) {
              navigate("/");
            }
          } else {
            if (
              currentUrl === "/quoteview" ||
              currentUrl === "/quotecreation" ||
              currentUrl.startsWith("/editQuote/") ||
              currentUrl.startsWith("/dspView/") ||
              currentUrl.startsWith("/externalSp/") ||
              currentUrl.startsWith("/quote/")
            ) {
              navigate("/");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        login();
      }
    });
  }, []);

  return (
    /* button to toggle opening and closing of sidebar */
    <div className={`sidebar ${collapsed.current ? "collapsed" : ""}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
        <img src={hamburger} alt="Toggle sidebar"></img>
      </button>
      <div className={`menus ${isSidebarVisible ? "visible" : "hidden"}`}>
        {/* display of orgname*/}
        <div className="child1">
          <ul>
            <li className="activeuser">
              <img src={users} alt="users" />
              <a href="">
                <span>{orgName}</span>
              </a>
            </li>
            {/* display of dashboard*/}
            <li className={activeHref === "/" ? "active" : ""}>
              {user && (
                <>
                  <a href="/">
                    <img src={dashboard} alt="dashboard" />
                  </a>
                  <a href="/" className="anchorText">
                    <span>{t("home_sidebar")}</span>
                  </a>
                </>
              )}
            </li>
            {/* display of projectlist*/}
            <li className={activeHref === "/projectlist" ? "active" : ""}>
              <a href="/projectlist">
                <img src={project} className="projecticon" alt="project" />
              </a>
              <a href="/projectlist">
                <span>{t("allProject_sidebar")}</span>
              </a>
            </li>
            {/* display of quotelis*/}
            <li className={activeHref === "/quoteview" ? "active" : ""}>
              {showQuote ? (
                <a href="/quoteview">
                  <img src={quote} alt="quote" />
                </a>
              ) : (
                <span>
                  <img src={quote} alt="quote" />
                </span>
              )}
              {showQuote ? (
                <a href="/quoteview">
                  <span>{t("quotes_sidebar")}</span>
                </a>
              ) : (
                <span>{t("quotes_sidebar")}</span>
              )}
            </li>
            {/* display of organisation list according to role base access*/}
            {suprmenAdmin ? (
              <li
                className={activeHref === "/manageOrganization" ? "active" : ""}
              >
                <a href="/manageOrganization">
                  <img src={reports} alt="reports" />
                </a>
                <a href="/manageOrganization">
                  <span>{t("organisation_sidebar")}</span>
                </a>
              </li>
            ) : null}
            {/* display of user list according to role base access*/}
            {suprmenAdmin || orgAdmin ? (
              <li className={activeHref === "/manageUser" ? "active" : ""}>
                <a href="/manageUser">
                  <img src={userslogo} alt="Users" />
                </a>
                <a href="/manageUser">
                  <span>{t("users_sidebar")}</span>
                </a>
              </li>
            ) : null}

            <li className={activeHref === "/reportList" ? "active" : ""}>
              <a href="/reportList">
                <img src={reportlistlogo} alt="report" />
              </a>
              <a href="/reportList">
                <span>{t("reportList")}</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="child2">
          <ul>
            {/* display of username or login button according to user status*/}
            <li className={activeHref === "" ? "active" : ""}>
              <a href="/portalUser">
                <img src={username} alt="username" />
              </a>
              {user ? (
                <a href="/portalUser">
                  <span>{user ? user.profile.name : ""}</span>
                </a>
              ) : (
                <a href="" onClick={login}>
                  <span>{t("login_sidebar")}</span>
                </a>
              )}
            </li>
            <li>
              {/* display of logout button*/}
              {user && (
                <>
                  <a href="#">
                    <img src={logouts} alt="logout" />
                  </a>
                  <a href="#" onClick={logout}>
                    <span>{t("logout_sidebar")}</span>
                  </a>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
