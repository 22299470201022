import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./report_list.module.css";
import { PageUtility } from "./components/pageUtility/pageUtility";
import { PageHeader } from "./components/pageHead/pageHead";
import { TableHeader } from "./components/tableHeader/tableHeader";
import { TableFilters } from "./components/tableFilters/tableFilter";
import { TableData } from "./components/tableData/tableData";
import { fetchTableData } from "./api/fetchTableData";
import { useSortingContext } from "../../context/sortingContext";
import { getUser } from "../../helper/auth_helper";
import api from "../../helper/auth_interceptor";
import { initializeSelectedColumns } from "./api/initialiseSelectedColumns";
import { saveColumnVisibility } from "./api/saveVisibleColumns";

export const ReportList = ({
  suprmenAdmin,
  orgAdmin,
  viewer,
  regular,
  currentMasterRole,
  masterUserOrgId,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const { sortOrder, sortColumn } = useSortingContext();
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedColumns, setSelectedColumns] = useState({
    projectName: true,
    orgName: true,
    role: true,
    startDate: true,
    endDate: true,
  });
  const dateRangeRef = useRef(null);
  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);
  const requestBody = useRef({
    startDate: null,
    endDate: null,
    searchText: null,
  });

  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
  };

  const handlePageChange = (newPageNumber) => {
    currentPage.current = newPageNumber;
    fetchData();
  };
  const pageNumber = currentPage.current;
  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchData();
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchTableData({
        requestBody: requestBody.current,
        currentPage: currentPage.current,
        selectedPageSize: selectedPageSize.current,
        sortColumn: sortColumn.current,
        sortOrder: sortOrder.current,
      });

      if (response && response.data) {
        setTableData(response.data);
        setTotalPages(response.totalPages);
      } else {
        setTableData([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  }, []);

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;

    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchData();
  };

  const handleResetColumns = () => {
    const defaultColumns = {
      projectName: true,
      orgName: true,
      role: true,
      startDate: true,
      endDate: true,
    };

    setSelectedColumns(defaultColumns);
    saveColumnVisibility(defaultColumns);
  };

  const columns = Object.keys(selectedColumns).filter(
    (column) => selectedColumns[column]
  );

  const handleToggleColumn = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = {
        ...prevSelectedColumns,
        [column]: !prevSelectedColumns[column],
      };
      saveColumnVisibility(updatedColumns);
      return updatedColumns;
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchInitialColumns = async () => {
      const initialColumns = await initializeSelectedColumns();
      setSelectedColumns(initialColumns);
    };

    fetchInitialColumns();
  }, []);

  return (
    <div className={styles.mainWrap}>
      <div className={styles.quoteBody}>
        <PageUtility onCheckboxChange={handleCheckboxChange} />
        <div
          className={`${styles.portfolio} ${
            isChecked ? styles.portfolioChecked : styles.portfolioUnchecked
          }`}
        >
          <PageHeader />
          <div className={`${styles.pageBody} ${styles.contentHt}`}>
            <div className={styles.jsonListRecord}>
              <div className={styles.jsonListDetails}>
                <TableHeader />
                <TableFilters
                  requestBody={requestBody}
                  handleSearchFormSubmit={handleSearchFormSubmit}
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                  dateRangeRef={dateRangeRef}
                  fetchData={fetchData}
                />

                <TableData
                  tableData={tableData}
                  totalPages={totalPages}
                  pageNumber={pageNumber}
                  handlePageChange={handlePageChange}
                  handlePageSize={handlePageSize}
                  fetchData={fetchData}
                  handleResetColumns={handleResetColumns}
                  onToggleColumn={handleToggleColumn}
                  columns={columns}
                  selectedColumns={selectedColumns}
                  currentMasterRole={currentMasterRole}
                  masterUserOrgId={masterUserOrgId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
