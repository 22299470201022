import styles from "./tableHeader.module.css";
import { useTranslation } from "react-i18next";
export const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.formHeadListProject}>
      <h2 className={styles.pageHeading}>{t("pageHeader_projectList")}</h2>
    </div>
  );
};
