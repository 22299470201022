import React, { createContext, useRef, useContext } from "react";

const SortingContext = createContext();

export const SortingContextProvider = ({ children }) => {
  const sortOrder = useRef();
  const sortColumn = useRef();

  return (
    <SortingContext.Provider value={{ sortOrder, sortColumn }}>
      {children}
    </SortingContext.Provider>
  );
};

export const useSortingContext = () => useContext(SortingContext);