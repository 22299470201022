import moment from "moment";
export const ValidateFields = (
  userDetails,
  startDate,
  endDate,
  t,
  newOrgId,
  isConvertionOn,
  suprmenAdmin
) => {
  const requiredFields = ["lastName", "firstName", "email", "status"];
  const fieldLabels = {
    lastName: t("lastName"),
    firstName: t("firstName"),
    email: t("emailAddress"),
    status: t("status"),
  };
  let errors = {};

  requiredFields.forEach((field) => {
    if (!userDetails.user[field]) {
      errors[field] = `${fieldLabels[field]} ${t("required_errorMessage")}`;
    }
  });

  if (isConvertionOn && suprmenAdmin) {
    const orgId = Number(newOrgId);
    if (!orgId) {
      errors["orgField"] = `${t("tabOneOrgName_projectEditProject")} ${t(
        "required_errorMessage"
      )}`;
    }
  }
  if (!startDate)
    errors["startDate"] = `${t("startDate")} ${t("required_errorMessage")}`;
  if (!endDate)
    errors["endDate"] = `${t("endDate")} ${t("required_errorMessage")}`;

  if (moment(endDate).isSameOrBefore(startDate)) {
    errors["endDate"] = `${t("endDate_errorMessage")}`;
  }

  return errors;
};
