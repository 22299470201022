import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import navigate from "../../../../images/navigate.png";
import "./addressModal.modules.css";
const AddressModal = ({ projectId, getAddresses, projectInfo }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [address, setAddress] = useState({
    streetName: "",
    city: "",
    postcode: "",
    houseNo: "",
    country: "",
    bagId: "",
    buildingId: "",
  });
  const [buildings, setBuildings] = useState([]);
  const currentPage = 1;
  const { t } = useTranslation();
  const openModal = () => {
    setModalIsOpen(true);
    fetchBuildings();
  };
  const closeModal = () => {
    setModalIsOpen(false);
    resetModal();
  };
  const fetchBuildings = async () => {
    const user = await getUser();
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/project/${projectId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      const response = await api.get(getApi, { headers });
      setBuildings(response.data);
      // If there's only one building, automatically select it
      if (response.data.length === 1) {
        setAddress((prev) => ({
          ...prev,
          buildingId: response.data[0].id,
        }));
      }
    } catch (error) {
      console.log("Error fetching buildings:", error);
    }
  };
  const handleSubmit = async () => {
    const user = await getUser();
    const requiredFields = [
      "country",
      "houseNo",
      "streetName",
      "city",
      "postcode",
      "buildingId",
    ];
    const fieldLabels = {
      country: t("country_editProjectTable"),
      streetName: t("streetName_editProjectTable"),
      city: t("city_editProjectTable"),
      postcode: t("postCode_editProjectTable"),
      houseNo: t("houseNumber_editProjectTable"),
      buildingId: t("building_editProjectTable"),
    };
    const newValidationErrors = {};
    let hasErrors = false;
    requiredFields.forEach((field) => {
      if (!address[field]) {
        newValidationErrors[field] = `${fieldLabels[field]} ${t(
          "required_errorMessage"
        )}`;
        hasErrors = true;
      }
    });
    if (hasErrors) {
      setValidationErrors(newValidationErrors);
      return;
    }
    const requestBody = {
      ...address,
      projectId: Number(projectId),
    };
    const getApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/address`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      await api.post(getApi, requestBody, { headers });
      toast(`${t("addressAdded")}`);
      closeModal();
      resetModal();
      getAddresses("", currentPage);
    } catch (error) {
      console.log("Error fetching data:", error);
      if (error?.response?.status === 409) {
        toast.error(`${t("errorAddingInfo_errorMessage")}`);
      }
    }
  };
  const handleInputChange = (field, value) => {
    setAddress((prev) => ({ ...prev, [field]: value }));
  };
  const handleBuildingChange = (event) => {
    const selectedBuildingId = event.target.value;
    setAddress((prev) => ({
      ...prev,
      buildingId: selectedBuildingId,
    }));
  };
  const resetModal = () => {
    setAddress({
      streetName: "",
      city: "",
      postcode: "",
      houseNo: "",
      country: "",
      bagId: "",
      buildingId: "",
    });
    setValidationErrors({});
  };
  const OpenAddressLink = () => {
    const zoomLevel = 1;
    const queryParams = `?searchQuery=${encodeURIComponent(
      address.houseNo +
        " " +
        address.streetName +
        " " +
        address.postcode +
        " " +
        address.city +
        " " +
        address.country
    )}&zoomlevel=${zoomLevel}`;
    const url = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/${queryParams}`;
    window.open(url, "_blank");
  };
  return (
    <div>
      {!projectInfo.readOnly ? (
        <button className="btn btn-success bulkUploadBtn" onClick={openModal}>
          <span>{t("pageHeader_addressModal")}</span>
        </button>
      ) : (
        <button className="btn btn-success bulkUploadBtn" disabled>
          <span>{t("pageHeader_addressModal")}</span>
        </button>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "80%",
            height: "70%",
            margin: "auto",
          },
        }}
      >
        <div className="modal-content">
          <div class="modal-header">
            <div>
              <h2>{t("addressModalBtn_addressModal")}</h2>
            </div>
            <div>
              <button className="closeBtn" onClick={closeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="25px"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="userdiv">
          <div className="addressDiv-content">
            <table>
              <thead>
                <tr>
                  <th>
                    {t("building_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("houseNumber_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("streetName_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("city_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("postCode_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>
                    {t("country_editProjectTable")}{" "}
                    <span className="required">*</span>
                  </th>
                  <th>{t("bagId_editProjectTable")}</th>
                  <th>
                    {t("preview__editProjectTable")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {buildings.length === 1 ? (
                      <p>{buildings[0]?.name}</p>
                    ) : (
                      <select
                        value={address.buildingId}
                        onChange={handleBuildingChange}
                        className={
                          validationErrors.buildingId ? "input-error" : ""
                        }
                      >
                        <option value="">{t("selectBuilding")}</option>
                        {buildings.map((building) => (
                          <option key={building.id} value={building.id}>
                            {building.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {validationErrors.buildingId && (
                      <span className="error-message">
                        {validationErrors.buildingId}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      value={address.houseNo}
                      onChange={(e) =>
                        handleInputChange("houseNo", e.target.value)
                      }
                      maxLength={50}
                      type="text"
                      className={validationErrors.houseNo ? "input-error" : ""}
                    ></input>
                    {validationErrors.houseNo && (
                      <span className="error-message">
                        {validationErrors.houseNo}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      value={address.streetName}
                      onChange={(e) =>
                        handleInputChange("streetName", e.target.value)
                      }
                      maxLength={50}
                      type="text"
                      className={
                        validationErrors.streetName ? "input-error" : ""
                      }
                    ></input>
                    {validationErrors.streetName && (
                      <span className="error-message">
                        {validationErrors.streetName}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      value={address.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      type="text"
                      maxLength={50}
                      className={validationErrors.city ? "input-error" : ""}
                    ></input>
                    {validationErrors.city && (
                      <span className="error-message">
                        {validationErrors.city}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      value={address.postcode}
                      onChange={(e) =>
                        handleInputChange("postcode", e.target.value)
                      }
                      maxLength={50}
                      type="text"
                      className={validationErrors.postcode ? "input-error" : ""}
                    ></input>
                    {validationErrors.postcode && (
                      <span className="error-message">
                        {validationErrors.postcode}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      value={address.country}
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                      maxLength={50}
                      type="text"
                      className={validationErrors.country ? "input-error" : ""}
                    ></input>
                    {validationErrors.country && (
                      <span className="error-message">
                        {validationErrors.country}
                      </span>
                    )}
                  </td>
                  <td>
                    <input
                      value={address.bagId}
                      onChange={(e) =>
                        handleInputChange("bagId", e.target.value)
                      }
                      maxLength={50}
                      type="text"
                    ></input>
                  </td>
                  <td>
                    <button
                      className="btn btn-success preview-button"
                      onClick={OpenAddressLink}
                    >
                      <img src={navigate} alt="navigation-icon" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-success modal-save-button"
            onClick={handleSubmit}
          >
            {t("submit")}
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default AddressModal;
