import React, { useState } from "react";
import styles from "./tableData.module.css";
import downArrow from "../../../../images/down.png";
import { DropdownRow } from "../dropdownRow/dropdownRow";
import { PaginationTool } from "../../../../utility/pagination";
import { RenderSortArrow } from "../../../commonUI/sortingArrows/sortingArrows";
import { useSortingContext } from "../../../../context/sortingContext";
import { useTranslation } from "react-i18next";
import { DropdownFilter } from "../../../../utility/dropdownFilters/ColumnFilter";

export const TableData = ({
  tableData,
  totalPages,
  pageNumber,
  handlePageChange,
  handlePageSize,
  fetchData,
  handleResetColumns,
  onToggleColumn,
  columns,
  selectedColumns,
  masterUserOrgId,
  currentMasterRole,
}) => {
  const { t } = useTranslation();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [projectRole, setProjectRole] = useState();
  const { sortOrder, sortColumn } = useSortingContext();

  const getProjectRole = (id, role) => {
    setProjectRole((prevData) => ({
      ...prevData,
      [id]: {
        role: role,
      },
    }));
  };

  const toggleDropdown = (id) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const getProjectRoleDisplayText = (role) => {
    const roleMapping = {
      SUPRMEN_ADMIN: t("SupRmen admin"),
      PROJECT_VIEWER: t("Project viewer"),
      PROJECT_USER: t("Project user"),
      PROJECT_LEADER: t("Project leader"),
    };
    return roleMapping[role] || role;
  };

  return (
    <div className={styles.reportListDetailsTableContent}>
      <div className={styles.tableContent}>
        <div className={styles.list}>
          <table>
            <thead className={styles.theadColumn}>
              <tr className={styles.recordRow}>
                {selectedColumns.projectName && (
                  <th className={styles.ForProjectRow}>
                    <div className={styles.tableHeader}>
                      {t("projectName_table")}
                      <RenderSortArrow
                        column="projectName"
                        onSort={fetchData}
                        activeColumn={sortColumn.current}
                        activeOrder={sortOrder.current}
                      />
                    </div>
                  </th>
                )}
                {selectedColumns.orgName && (
                  <th className={styles.FororganizationNameRow}>
                    <div className={styles.tableHeader}>
                      {t("organisationName_table")}
                      <RenderSortArrow
                        column="orgName"
                        onSort={fetchData}
                        activeColumn={sortColumn.current}
                        activeOrder={sortOrder.current}
                      />
                    </div>
                  </th>
                )}
                {selectedColumns.role && (
                  <th className={styles.ForProjectRoleRow}>
                    <div className={styles.tableHeader}>
                      {t("projectRole_table")}
                      <RenderSortArrow
                        column="role"
                        onSort={fetchData}
                        activeColumn={sortColumn.current}
                        activeOrder={sortOrder.current}
                      />
                    </div>
                  </th>
                )}
                {selectedColumns.startDate && (
                  <th className={styles.ForStartRow}>
                    <div className={styles.tableHeader}>
                      {t("startDate_table")}
                      <RenderSortArrow
                        column="startDate"
                        onSort={fetchData}
                        activeColumn={sortColumn.current}
                        activeOrder={sortOrder.current}
                      />
                    </div>
                  </th>
                )}
                {selectedColumns.endDate && (
                  <th className={styles.ForEndRow}>
                    <div className={styles.tableHeader}>
                      {t("endDate_table")}
                      <RenderSortArrow
                        column="endDate"
                        onSort={fetchData}
                        activeColumn={sortColumn.current}
                        activeOrder={sortOrder.current}
                      />
                    </div>
                  </th>
                )}
                <DropdownFilter
                  columns={Object.keys(selectedColumns)}
                  selectedColumns={selectedColumns}
                  onToggleColumn={onToggleColumn}
                  handleResetColumns={handleResetColumns}
                />
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => {
                const hasVisibleColumns =
                  selectedColumns.projectName ||
                  selectedColumns.orgName ||
                  selectedColumns.role ||
                  selectedColumns.startDate ||
                  selectedColumns.endDate;

                return (
                  <React.Fragment key={item.id}>
                    {hasVisibleColumns && (
                      <tr
                        className={
                          openDropdowns[item.id] ? styles.clickedRow : ""
                        }
                      >
                        {selectedColumns.projectName && (
                          <td className={styles.ForProjectRow}>
                            <button
                              className={styles.dropdownBtn}
                              onClick={() => {
                                toggleDropdown(item.id);
                                getProjectRole(item.id, item.projectRole);
                              }}
                            >
                              <img
                                className={`${styles.downImg} ${
                                  openDropdowns[item.id] ? styles.rotated : ""
                                }`}
                                src={downArrow}
                                alt="dropdown arrow"
                              />
                            </button>
                            {item.projectName}
                          </td>
                        )}
                        {selectedColumns.orgName && (
                          <td className={styles.FororganizationNameRow}>
                            {item.organizationName}
                          </td>
                        )}
                        {selectedColumns.role && (
                          <td className={styles.ForProjectRoleRow}>
                            {getProjectRoleDisplayText(item.projectRole)}
                          </td>
                        )}
                        {selectedColumns.startDate && (
                          <td className={styles.ForStartRow}>
                            {item.startDate}
                          </td>
                        )}
                        {selectedColumns.endDate && (
                          <td className={styles.ForEndRow}>{item.endDate}</td>
                        )}
                        <td></td>
                      </tr>
                    )}

                    {openDropdowns[item.id] && (
                      <tr className={styles.dropDownRow}>
                        <td colSpan={9}>
                          <DropdownRow
                            id={item.id}
                            projectRole={projectRole}
                            orgId={item.organizationId}
                            masterUserOrgId={masterUserOrgId}
                            currentMasterRole={currentMasterRole}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <PaginationTool
        pageNumbers={totalPages}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
      />
    </div>
  );
};
