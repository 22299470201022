import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const proscessingStatus = async (id) => {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/v1/${id}`;
  try {
    const response = await api.get(apiUrl, {
      headers,
    });
    if (response) {
      return { status: response.data.processingStatus };
    }
  } catch (error) {
    console.log(error);
  }
};
