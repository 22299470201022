import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";
export const fetchBuilding = async (id) => {
  const user = await getUser();

  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/project/${id}`;

  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  const response = await api.get(apiUrl, {
    headers,
  });

  return { data: response.data };
};
