export const convertUtcToAmsterdamTime = (time) => {
  if (!time) return "-"; 

  const utcDate = new Date(`1970-01-01T${time}Z`); 
  const amsterdamTime = utcDate.toLocaleString("en-US", {
    timeZone: "Europe/Amsterdam",
    hour12: false, 
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return amsterdamTime;
};
