import React, { useState, useEffect, useRef } from "react";
import { getUser } from "../../../../../helper/auth_helper";
import { useTranslation } from 'react-i18next';
import api from "../../../../../helper/auth_interceptor";
import "./partnerAccordian.modules.css";
import { OutsidePop } from "./component/outsidePop";
import { InsidePop } from "./component/insidePop";
import { InspectionPop } from "./component/inspectionPop";
import { Planning } from "./component/planning/planning";
// import { ViewPop } from "./component/viewPop";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Warning } from "@mui/icons-material";
import CurrencyInput from 'react-currency-input-field';

const defaultPlanning = {
  desiredStartDateFrom: "",
  desiredStartDateTo: "",
  desiredDeliveryDateFrom: "",
  desiredDeliveryDateTo: "",
};

const defaultErrorDates = {
  desiredStartDate: "",
  desiredEndDate: "",
};

export const Partner = ({
  setNewQuoteDetails,
  newQuoteDetails,
  id,
  validationCheck,
  quotePartners,
  inpectionSelected,
  offerRecieved,
}) => {
  const navigate = useNavigate();
  const [dsp, setDsp] = useState([]);
  const [internalPartners, setInternalPartners] = useState([]);
  const [inspectionPartners, setInspectionPartners] = useState([]);
  const [planningDetails, setPlanningDetails] = useState(defaultPlanning);
  const [outsideCommission, setOutsideCommission] = useState(null);
  const [internalCommission, setInternalCommision] = useState(null);
  const [inspectionExtraCom, setInspectionExtraCom] = useState(null);
  const [inspectionCom, setInspectionCom] = useState(null);
  const [dateErrors, setDateErrors] = useState(defaultErrorDates);
  const { t } = useTranslation();
  const CheckInputItem = {
    position: "relative",
    top: "5px",
    width: "20px!Important",
    height: "20px!Important",
    padding: "11px",
    left: "5px",
    marginRight: "5px",
    border:"1px solid #d9dede"
  };

  const CheckInputItemSecond = {
    position: "relative",
    top: "5px",
    width: "20px!Important",
    height: "20px!Important",
    padding: "9px",
    left: "5px",
    marginRight: "5px",
  };

  const OfferLabel = {
    fontWeight: "500",
    color: "#ff6b00",
    textTransform: "capitalize",
  };

  const OfferLabelSpan = {
    fontWeight: "500",
    color: "#6d6d6d",
    textTransform: "capitalize",
    fontSize: "13px",
  };

  const MainScanDivLabel = {
    paddingTop: "25px",
  }

  const MainScanDivTabContent = {
    padding: "0px 25px 10px 25px"
  }
  const MainScanDivLabelH2 = {
    paddingLeft: "25px"
  }

  const buttonPadding = {
    marginTop: "40px", marginLeft: "15px"
  }

  const LabelPadding = {
    padding: "10px 0"
  }

  const commissionLabel = {
    marginTop: "15px"
  }

  const TableRowBorder = {
    borderBottom: "1px solid #f9f9f9"
  }

  const TableColAlign = {
    textAlign: "center"
  }
  const RightAlign = {textAlign: "right" }
  const cancelBtn={
    textDecoration: "none",
    color: "white"
  }

  const offerLabelType = {
    OFFER_SENT: "Offer received",
    QUOTE_RECEIVED: "Quote sent",
    ACCEPTED: "Offer selected",
    REJECTED: "Offer rejected",
  };


  function getOfferLabel(status) {
    switch (status) {
      case "OFFER_SENT":
        return offerLabelType.OFFER_SENT;
      case "QUOTE_RECEIVED":
        return offerLabelType.QUOTE_RECEIVED;
      case "ACCEPTED":
        return offerLabelType.ACCEPTED;
      case "REJECTED":
        return offerLabelType.REJECTED;
      default:
        return "Unknown status";
    }
  }

  async function getDSP() {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/dsp `;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setDsp(response.data);
      }
    } catch (error) { }
  }

  async function getInspectionReportPartners() {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/inspection `;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setInspectionPartners(response.data);
      }
    } catch (error) { }
  }
  async function getInternalPartners() {
    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/internal `;

    try {
      const response = await api.get(apiUrl, {
        headers,
      });
      if (response) {
        setInternalPartners(response.data);
      }
    } catch (error) { }
  }

  useEffect(() => {
    getDSP();
    getInspectionReportPartners();
    getInternalPartners();
  }, []);

  const [selectedDSP, setSelectedDSP] = useState([]);
  const [offerSentDspId, setOfferSentDspId] = useState();
  const [selectedInspection, setSelectedInspection] = useState([]);
  const [selectedInternal, setSelectedInternal] = useState([]);
  const [dateCheck, setDateCheck] = useState(false);

  let ExternalPartnerMessageDisplayed = false;
  let internalPartnerMessageDisplayed = false;
  let inspectionPartnerMessageDisplayed = false;
  const handleCheckboxChange = (e, category, id) => {
    const { checked } = e.target;

    // Check if value is valid and a number
    const numericId = !isNaN(id) ? Number(id) : null;

    if (numericId !== null) {
      let updatedSelectedDSP = [...selectedDSP];
      let updatedSelectedInternal = [...selectedInternal];
      let updatedSelectedInspection = [...selectedInspection];

      switch (category) {
        case "DSP":
          if (checked) {
            updatedSelectedDSP.push(numericId);
          } else {
            updatedSelectedDSP = updatedSelectedDSP.filter(
              (item) => item !== numericId
            );
          }

          break;
        case "Inspection":
          if (checked) {
            updatedSelectedInspection.push(numericId);
          } else {
            updatedSelectedInspection = updatedSelectedInspection.filter(
              (item) => item !== numericId
            );
          }
          break;
        case "Internal":
          if (checked) {
            updatedSelectedInternal.push(numericId);
          } else {
            updatedSelectedInternal = updatedSelectedInternal.filter(
              (item) => item !== numericId
            );
          }
          break;
        default:
          break;
      }

      setSelectedDSP(updatedSelectedDSP);
      setSelectedInternal(updatedSelectedInternal);
      setSelectedInspection(updatedSelectedInspection);
    } else {
    }
  };

  const handleRadioChange = (e, category) => {
    const { value } = e.target;
    // Check if value is valid and a number
    const numericId = !isNaN(value) ? Number(value) : null;

    if (numericId !== null) {
      switch (category) {
        case "DSP":
          setOfferSentDspId(numericId);

          break;
        
        default:
          break;
      }

    }
  };

  const handleInputChangeCommission = (event) => {
    const { name, value } = event.target;
    if (name === 'InternalCommisionInput') {
      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");
     
      setInternalCommision(cleanValue)
    }
    if (name === 'InspectionPartnerExtraCommisionInput') {
      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");
    
      setInspectionExtraCom(cleanValue)
    }
    if (name === 'InspectionPartnerCommisionInput') {
      const cleanValue = value.replace(/[^\d,]/g, "").replace(/,/g, ".");
     
      setInspectionCom(cleanValue)
    }

  }
  const format1 = "DD-MM-YYYY";

  const requestBody = {
    quoteId: Number(id),
    inspectionPartnerOrgId: Number(...selectedInspection)
      ? Number(...selectedInspection)
      : null,
    insidePartnerOrgId: Number(...selectedInternal)
      ? Number(...selectedInternal)
      : null,
    outsidePartnerOrgId: offerSentDspId
      ? offerSentDspId
      : Number(...selectedDSP)
        ? Number(...selectedDSP)
        : null,
    outsidePartnerCommission: Number(outsideCommission),
    insidePartnerCommission: Number(internalCommission),
    inspectionPartnerExtraServiceCommission: Number(inspectionExtraCom),
    inspectionPartnerCommission: Number(inspectionCom),
    deliveryDateBegin: moment(planningDetails.desiredDeliveryDateFrom).format(
      format1
    ),
    deliveryDateEnd: moment(planningDetails.desiredDeliveryDateTo).format(
      format1
    ),
  };

 
  const checkMissingDates = () => {
    const errors = { ...defaultErrorDates };

    if (!planningDetails.desiredStartDateFrom) {
      errors.desiredStartDate = `${t('startDate')} ${t('required_errorMessage')}`;
    }

    if (!planningDetails.desiredStartDateTo) {
      errors.desiredStartDate = `${t('startDate')}  ${t('required_errorMessage')}`;
    }
    if (!planningDetails.desiredDeliveryDateFrom) {
      errors.desiredEndDate = `${t('deliveryDate')}  ${t('required_errorMessage')}`;
    }
    if (!planningDetails.desiredDeliveryDateTo) {
      errors.desiredEndDate = `${t('deliveryDate')}   ${t('required_errorMessage')}`;
    }

    setDateErrors(errors);
  };

  const handleSubmit = async () => {
    checkMissingDates();

    if (
      !requestBody.deliveryDateBegin ||
      !requestBody.deliveryDateEnd
    ) {
      setDateCheck(true);

      return;
    }

    if (
      partnerTypes.includes("DRONE_OPERATOR") &&
      partnerTypes.includes("INTERNAL_PARTNER") &&
      partnerTypes.includes("INSPECTION_PARTNER")
    ) {
      if (
        !requestBody.outsidePartnerOrgId ||
        !requestBody.inspectionPartnerOrgId ||
        !requestBody.insidePartnerOrgId ||
        !requestBody.outsidePartnerCommission ||
        !requestBody.insidePartnerCommission ||
        !requestBody.inspectionPartnerExtraServiceCommission ||
        !requestBody.inspectionPartnerCommission ||
        !requestBody.deliveryDateBegin ||
        !requestBody.deliveryDateEnd
      ) {
        setDateCheck(true);

        return;
      }
    }

    if (partnerTypes.includes("DRONE_OPERATOR")) {
      if (
        !requestBody.outsidePartnerOrgId ||
        !requestBody.inspectionPartnerOrgId ||
        !requestBody.outsidePartnerCommission ||
        !requestBody.inspectionPartnerExtraServiceCommission ||
        !requestBody.inspectionPartnerCommission ||
        !requestBody.deliveryDateBegin ||
        !requestBody.deliveryDateEnd
      ) {
        setDateCheck(true);

        return;
      }
    }

    if (partnerTypes.includes("INTERNAL_PARTNER")) {
      if (
        !requestBody.insidePartnerOrgId ||
        !requestBody.insidePartnerOrgId ||
        !requestBody.deliveryDateBegin ||
        !requestBody.deliveryDateEnd
      ) {
        setDateCheck(true);

        return;
      }
    }

    const user = await getUser();
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/offer `;

    try {
      const response = await api.post(apiUrl, requestBody, {
        headers,
      });
      if (response) {
        navigate("/quoteview");
        toast.success(`${t('quoteUpdated')}`);
      }
    } catch (error) { }
  };

  const scanType = newQuoteDetails.productType;
  const partnerTypes = quotePartners.map((partner) => partner.partnerType);


  // console.log("quotePartners",quotePartners)
  return (
    <div className="form-head">
      <section class={`accordion ${dateCheck ? "no-selection" : ""}`}>
        {newQuoteDetails.quoteStatusType !== "QUOTE_FORWARDED" && (
          <div className="tab">
            <input type="checkbox" name="accordion-1" id="cb2" checked />
            <label for="cb2" className="tab-label form-head quote-detail"  >
              <h2 style={MainScanDivLabelH2}>{t('selectPartner_partnerAccord')}</h2>
            </label>
            <div class="tab-content" style={MainScanDivTabContent}>
              {(scanType === "OUTSIDE" || scanType === "BOTH") && (
                <div>
                  <div class="check-header">
                    <div class="check-header-title">
                      {/* <input type="checkbox" id="dsp" /> */}
                      <label for="DSP">{t('dsp_partnerAccord')}</label>
                    </div>
                  </div>
                  <div class="check-options inspection">
                    {dsp?.map((item) => (
                      <div class="check">
                        <input
                          style={CheckInputItem}
                          type="checkbox"
                          value={item.id}
                          onChange={(e) =>
                            handleCheckboxChange(e, "DSP", item.id)
                          }
                        />
                        <label for={item.organizationType}>{item.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {(scanType === "OUTSIDE" || scanType === "BOTH") && (
                <div>
                  <div class="check-header">
                    <div class="check-header-title">
                    
                      <label for="Inspection">{t('inpectionPartner_partnerAccord')}</label>
                    </div>
                  </div>
                  <div class="check-options inspection">
                    {inspectionPartners?.map((item) => (
                      <div class="check">
                        <input
                          style={CheckInputItem}
                          type="checkbox"
                          value={item.id}
                          onChange={(e) =>
                            handleCheckboxChange(e, "Inspection")
                          }
                        />
                        <label for={item.organizationType}>{item.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {(scanType === "INSIDE" || scanType === "BOTH") && (
                <div>
                  <div class="check-header">
                    <div class="check-header-title">
                     
                      <label for="internal">{t('internalPartner_partnerAccord')}</label>
                    </div>
                  </div>
                  <div class="check-options inspection">
                    {internalPartners?.map((item) => (
                      <div class="check">
                        <input
                          style={CheckInputItem}
                          type="checkbox"
                          value={item.id}
                          onChange={(e) => handleCheckboxChange(e, "Internal")}
                        />
                        <label for={item.organizationType}>{item.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {newQuoteDetails.quoteStatusType === "QUOTE_FORWARDED" && (
          <div className="tab">
            <input type="checkbox" name="accordion-1" id="cb2" />
            <label
              htmlFor="cb2"
              className="tab-label form-head quote-detail SelctPartnrLabel"

            >
              <h2 style={MainScanDivLabelH2}>{t('selectedPartner_partnerAccord')}</h2>
            </label>
            <div className="tab-content" style={MainScanDivTabContent}>
              <table className="partnersTable">
                <thead>
                  <tr >
                    <th>Partner Type</th>
                    <th>Partner Name</th>
                    <th>Offer Status</th>
                    <th></th>
                    <th style={TableColAlign}>Action</th>
                    <th style={TableColAlign}>Additional Details</th>
                  </tr>
                </thead>
                <tbody>
                  {quotePartners.map((partner) => (
                    <tr key={partner.orgId} style={TableRowBorder}>
                      <td>
                        {partner.partnerType ? (partner.partnerType.replace(/_/g, ' ')) : ''}
                      </td>
                      <td style={OfferLabel}>
                        <label htmlFor="partnerName" style={LabelPadding}>{partner.orgName}</label>
                      </td>
                      <td>
                        <span>{getOfferLabel(partner.quotePartnerStatus)}</span>
                      </td>
                      {
                        partner.isExecutionDateFavourable === false ? (
                          <div style={{ padding: "10px 0px"}}>
                            <Warning style={{color:"darkorange"}} />
                          </div>
                        ) : (
                          <div>
                            
                          </div>
                        )
                      }
                      <td style={TableColAlign}>
                        {partner.quotePartnerStatus === "OFFER_SENT" && (
                          <>
                            {partner.partnerType === "DRONE_OPERATOR" && (
                              <input
                                style={CheckInputItem}
                                type="radio"
                                value={partner.partnerOrgId}
                                name="dsp"
                                className="greyBackgroundRadio"
                                onChange={(e) => handleRadioChange(e, "DSP")}
                              />
                            )}
                            {(partner.partnerType === "INTERNAL_PARTNER" || partner.partnerType === "INSPECTION_PARTNER") && (
                              <input
                                style={CheckInputItemSecond}
                                type="checkbox"
                                value={partner.partnerOrgId}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    partner.partnerType === "INTERNAL_PARTNER" ? "Internal" : "Inspection",
                                    partner.partnerOrgId
                                  )
                                }
                              />
                            )}

                          </>
                        )}
                      </td>
                      <td style={TableColAlign}>
                        {partner.quotePartnerStatus === "OFFER_SENT" && (
                          <>
                            {partner.partnerType === "DRONE_OPERATOR" && (
                              <OutsidePop
                                orgId={partner.partnerOrgId}
                                quoteId={partner.quoteId}
                              />
                            )}
                            {partner.partnerType === "INTERNAL_PARTNER" && (
                              <InsidePop
                                orgId={partner.partnerOrgId}
                                quoteId={partner.quoteId}
                              />
                            )}
                            {partner.partnerType === "INSPECTION_PARTNER" && (
                              <InspectionPop
                                orgId={partner.partnerOrgId}
                                quoteId={partner.quoteId}
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>



              {quotePartners.map((partner) => (
                partner.quotePartnerStatus === "OFFER_SENT" && (
                  <React.Fragment key={partner.orgId}>
                    {partner.partnerType === "DRONE_OPERATOR" && (
                      dateCheck && !requestBody.outsidePartnerOrgId && (
                      
                        !ExternalPartnerMessageDisplayed && (
                          <div className="message">
                            {t('errorMsg1_partnerAccord')}
                          </div>
                        )
                      )
                    )}
                    
                    {partner.partnerType === "DRONE_OPERATOR" && (
                      ExternalPartnerMessageDisplayed = true
                    )}

                    {partner.partnerType === "INTERNAL_PARTNER" && (
                      dateCheck && !requestBody.insidePartnerOrgId && (
                       
                        !internalPartnerMessageDisplayed && (
                          <div className="message">
                            {t('errorMsg2_partnerAccord')}
                          </div>
                        )
                      )
                    )}
                 
                    {partner.partnerType === "INTERNAL_PARTNER" && (
                      internalPartnerMessageDisplayed = true
                    )}

                    {partner.partnerType === "INSPECTION_PARTNER" && (
                      dateCheck && !requestBody.inspectionPartnerOrgId && (
                      
                        !inspectionPartnerMessageDisplayed && (
                          <div className="message">
                            {t('errorMsg3_partnerAccord')}
                          </div>
                        )
                      )
                    )}
                    
                    {partner.partnerType === "INSPECTION_PARTNER" && (
                      inspectionPartnerMessageDisplayed = true
                    )}
                  </React.Fragment>
                )
              ))}

           

              {offerRecieved && (
                <div>
                  <div>
                    <h4 style={commissionLabel}>{t('commisions_partnerAccord')}</h4>
                    <div className="OutsidePartCommMainDiv">
                      {partnerTypes.includes("DRONE_OPERATOR") && (
                        <div className="OutsidePartComm">
                          <label className="outsidePartComLabel">
                            <h5>{t('outsideCommision_partnerAccord')}(0-100%)</h5>
                          </label>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            style={RightAlign}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (!isNaN(value) && value >= 1 && value <= 100) {
                                setOutsideCommission(value);
                              }
                            }}
                            className="form-control"
                          />
                          {dateCheck &&
                            !requestBody.outsidePartnerCommission && (
                              <div className="message">
                               {t('errorMsg4_partnerAccord')}
                              </div>
                            )}
                        </div>
                      )}

                      {partnerTypes.includes("INTERNAL_PARTNER") && (
                        <div className="OutsidePartComm">
                          <label>
                            <h5>{t('insideCommision_partnerAccord')}(€)</h5>
                          </label>
                          <div>
                                  <CurrencyInput
                                    name="InternalCommisionInput"
                                    className="form-control"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    prefix=""
                                    style={RightAlign}
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputChangeCommission(event)
                                    }
                                  />

                                </div>
                       
                          {dateCheck &&
                            !requestBody.insidePartnerCommission && (
                              <div className="message">
                               {t('errorMsg4_partnerAccord')}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                    {partnerTypes.includes("INSPECTION_PARTNER") && (
                      <div className="InspectionpartnerCommMainDiv">
                        <label>
                          <h5>{t('inspectionCommision_partnerAccord')}(€)</h5>
                        </label>
                        <div className="OutsidePartCommMainDiv">
                          <div className="OutsidePartComm">
                            <label>
                              {t('inspectionExtraCommision_partnerAccord')}(€)
                            </label>
                            <div>
                                  <CurrencyInput
                                    name="InspectionPartnerExtraCommisionInput"
                                    className="form-control"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    prefix=""
                                    style={RightAlign}
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputChangeCommission(event)
                                    }
                                  />

                                </div>
                          
                            {dateCheck &&
                              !requestBody.inspectionPartnerExtraServiceCommission && (
                                <div className="message">
                                  {t('errorMsg4_partnerAccord')}
                                </div>
                              )}
                          </div>
                          <div className="OutsidePartComm">
                            <label>{t('inspectionCommision_partnerAccord')}(€)</label>
                            <div>
                                  <CurrencyInput
                                    name="InspectionPartnerCommisionInput"
                                    className="form-control"
                                    placeholder=""
                                    allowDecimals={true}
                                    decimalsLimit={2}
                                    prefix=""
                                    style={RightAlign}
                                    intlConfig={{ locale: 'nl-NL', currency: 'EUR' }}
                                    onChange={(event) =>
                                      handleInputChangeCommission(event)
                                    }
                                  />

                                </div>
                           
                            {dateCheck &&
                              !requestBody.inspectionPartnerCommission && (
                                <div className="message">
                                 {t('errorMsg4_partnerAccord')}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <Planning
                      planningDetails={planningDetails}
                      setPlanningDetails={(planningDetails) =>
                        setPlanningDetails(planningDetails)
                      }
                      dateErrors={dateErrors}
                    />
                  </div>

                  <button
                    style={buttonPadding}
                    className="btn btn-success modal-save-button"
                    
                  >
                    <a href="/quoteview"   style={cancelBtn} className="quotelink"> {t('cancel')}</a>
                  </button>

                  <button
                    style={buttonPadding}
                    className="btn btn-success modal-save-button"
                    onClick={() => handleSubmit()}
                  >
                    {t('submit')}
                  </button>

                  
                </div>
              )}
            </div>
          </div>
        )}
      </section>
      {dateCheck && (
        <div className="message">{t('pleaseFillFields_errorMessage')}</div>
      )}
    </div>
  );
};
