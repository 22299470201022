import styles from "./pageHead.module.css";
import { useTranslation } from "react-i18next";

export const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainHeaderContent}>
      <div className={styles.pageHeader}>
        <h1>{t("reportList")}</h1>
      </div>
    </div>
  );
};
