export const FormatNumberEuStandard = (number) => {
    if (number === null || number === undefined) return null;
  
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    return formatter.format(number);
  };
  