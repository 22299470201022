import styles from "./DataTable.module.css";
import { RenderSortArrow } from "../../../commonUI/sortingArrows/sortingArrows";
import { useTranslation } from "react-i18next";
import { DropdownFilter } from "../../../../utility/dropdownFilters/ColumnFilter";
import { useSortingContext } from "../../../../context/sortingContext";

export const TableHead = ({
  selectedColumns,
  fetchTableData,
  conditieMetingToggle,
  handleToggleColumn,
  handleResetColumns,
}) => {
  const { t } = useTranslation();
  const { sortOrder, sortColumn } = useSortingContext();
  return (
    <thead className={styles.theadColumn}>
      <tr className={styles.recordRow}>
        {selectedColumns.elementName && (
          <th
            className={`${styles.elementRow} ${styles.rightColumn} ${styles.marginColumn}`}
          >
            <div className={styles.tableHeader}>
              {t("elementName")}{" "}
              <RenderSortArrow
                column="elementName"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}
        {selectedColumns.detection && (
          <th className={`${styles.detectionRow} ${styles.rightColumn}`}>
            {t("detection")}
          </th>
        )}
        {selectedColumns.viewIn3D && (
          <th className={`${styles.viewRow} ${styles.rightColumn}`}>
            {t("viewIn3D")}
          </th>
        )}
        {conditieMetingToggle ? (
          <>
            {selectedColumns.afwijking && (
              <th className={`${styles.deviationRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("deviation")}
                  <RenderSortArrow
                    column="afwijking"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.toelichting && (
              <th
                className={`${styles.explanationRow} ${styles.rightColumn} ${styles.marginColumn}`}
              >
                <div className={styles.tableHeader}>
                  {t("explanation")}
                  <RenderSortArrow
                    column="toelichting"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.locatieDak && (
              <th className={`${styles.locationRoofRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("locationRoof")}
                  <RenderSortArrow
                    column="locatieDak"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.locatieBuitenwand && (
              <th className={`${styles.facadeRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("locationFacade")}
                  <RenderSortArrow
                    column="locatieBuitenwand"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.intensiteit && (
              <th className={`${styles.intensityRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("intensity")}
                  <RenderSortArrow
                    column="intensiteit"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.omvang && (
              <th className={`${styles.extentRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("extent")}
                  <RenderSortArrow
                    column="omvang"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.risicoaspect && (
              <th className={`${styles.aspectRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("riskAspect")}
                  <RenderSortArrow
                    column="risicoaspect"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
            {selectedColumns.prioriteit && (
              <th className={`${styles.priorityRow} ${styles.rightColumn}`}>
                <div className={styles.tableHeader}>
                  {t("priority")}
                  <RenderSortArrow
                    column="prioriteit"
                    onSort={fetchTableData}
                    activeColumn={sortColumn.current}
                    activeOrder={sortOrder.current}
                  />
                </div>
              </th>
            )}
          </>
        ) : null}
        {selectedColumns.type && (
          <th className={`${styles.typeRow} ${styles.rightColumn}`}>
            <div className={styles.tableHeader}>
              Type{" "}
              <RenderSortArrow
                column="type"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}
        {selectedColumns.location && (
          <th className={`${styles.locationRow} ${styles.rightColumn}`}>
            <div className={styles.tableHeader}>
              {t("location")}
              <RenderSortArrow
                column="location"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}

        {selectedColumns.length && (
          <th
            className={`${styles.lengthRow} ${styles.rightColumn} ${styles.extraWidth}`}
          >
            <div className={styles.tableHeader}>
              {t("length")}
              <RenderSortArrow
                column="length"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}

        {selectedColumns.area && (
          <th
            className={`${styles.areaRow} ${styles.rightColumn} ${styles.extraWidth}`}
          >
            <div className={styles.tableHeader}>
              {t("area")} m²
              <RenderSortArrow
                column="area"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}

        {selectedColumns.quantity && (
          <th
            className={`${styles.quantityRow} ${styles.rightColumn} ${styles.extraWidth}`}
          >
            <div className={styles.tableHeader}>
              {t("quantity")}
              <RenderSortArrow
                column="quantity"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}

        {selectedColumns.lengthTotal && (
          <th
            className={`${styles.lengthTotalRow} ${styles.rightColumn} ${styles.extraWidth}`}
          >
            <div className={styles.tableHeader}>
              {t("lengthTotal")}
              <RenderSortArrow
                column="lengthTotal"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}
        {selectedColumns.grossTotal && (
          <th
            className={`${styles.grossRow} ${styles.rightColumn} ${styles.extraWidth}`}
          >
            <div className={styles.tableHeader}>
              {t("grossTotal")}
              <RenderSortArrow
                column="grossTotal"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}
        {selectedColumns.netTotal && (
          <th
            className={`${styles.netRow} ${styles.rightColumn} ${styles.extraWidth}`}
          >
            <div className={styles.tableHeader}>
              {t("netTotal")}
              <RenderSortArrow
                column="netTotal"
                onSort={fetchTableData}
                activeColumn={sortColumn.current}
                activeOrder={sortOrder.current}
              />
            </div>
          </th>
        )}
        <th className={styles.emptyColumn}></th>
        <DropdownFilter
          columns={Object.keys(selectedColumns)}
          selectedColumns={selectedColumns}
          onToggleColumn={handleToggleColumn}
          handleResetColumns={handleResetColumns}
          conditieMetingToggle={conditieMetingToggle}
        />
      </tr>
    </thead>
  );
};
