import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const initializeSelectedColumns = async () => {
    const user = await getUser();

    const page = "PROJECT";
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/${page}`;

    try {
      const response = await api.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const savedSettings = response.data.selectedColumns.split(",");
      const defaultColumns = {
        projectName: true,
        orgName: true,
        role: true,
        startDate: true,
        endDate: true,
      };

      if (savedSettings.length > 0) {
        const updatedColumns = { ...defaultColumns };
        Object.keys(defaultColumns).forEach((column) => {
          updatedColumns[column] = savedSettings.includes(column);
        });
        return updatedColumns;
      }

      return defaultColumns;
    } catch (error) {
      console.error("Error fetching column visibility:", error);
      return {
        projectName: true,
        orgName: true,
        role: true,
        startDate: true,
        endDate: true,
      };
    }
  };
