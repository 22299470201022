import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const getOrgDetails = async () => {
  const user = await getUser();
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization`;
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  try {
    const response = await api.get(apiUrl, {
      headers,
    });

    return {
      orgList: response.data,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
