import styles from "./DataTable.module.css";
import { useTranslation } from "react-i18next";
import { FormatNumberEuStandard } from "../../../commonUtility/euStandardFormatCovert";
export const TableTotal = ({
  currentToggle,
  selectedColumns,
  summaryData,
  conditieMetingToggle,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {currentToggle === true ? (
        <tr className={styles.showTotalRow}>
          {selectedColumns.elementName && (
            <td className={styles.boldText}>{t("total")}</td>
          )}
          {selectedColumns.detection && <td className={styles.boldText}></td>}

          {selectedColumns.viewIn3D && <td className={styles.boldText}></td>}
          {conditieMetingToggle ? (
            <>
              {selectedColumns.afwijking && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.toelichting && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.locatieDak && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.locatieBuitenwand && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.intensiteit && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.omvang && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.risicoaspect && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
              {selectedColumns.prioriteit && (
                <td
                  className={`${styles.boldText} ${styles.showTotalItem}`}
                ></td>
              )}
            </>
          ) : null}

          {selectedColumns.type && <td className={styles.boldText}> </td>}
          {selectedColumns.location && <td className={styles.boldText}></td>}

          {selectedColumns.length && (
            <td className={`${styles.boldText} ${styles.showTotalItem}`}>
              {FormatNumberEuStandard(summaryData.sumOfLength)}
            </td>
          )}
          {selectedColumns.area && (
            <td className={`${styles.boldText} ${styles.showTotalItem}`}>
              {FormatNumberEuStandard(summaryData.sumOfArea)}
            </td>
          )}
          {selectedColumns.quantity && (
            <td className={`${styles.boldText} ${styles.showTotalItem}`}>
              {summaryData.sumOfQuantity}
            </td>
          )}

          {selectedColumns.lengthTotal && (
            <td className={`${styles.boldText} ${styles.showTotalItem}`}>
              {FormatNumberEuStandard(summaryData.sumOfLengthTotal)}
            </td>
          )}
          {selectedColumns.grossTotal && (
            <td className={`${styles.boldText} ${styles.showTotalItem}`}>
              {FormatNumberEuStandard(summaryData.sumOfGrossTotal)}
            </td>
          )}
          {selectedColumns.netTotal && (
            <td className={`${styles.boldText} ${styles.showTotalItem}`}>
              {FormatNumberEuStandard(summaryData.sumOfNetTotal)}
            </td>
          )}

          <td></td>
          <td></td>
        </tr>
      ) : null}
    </>
  );
};
