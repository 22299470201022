import { useSortingContext } from "../../../context/sortingContext";
import styles from "./sortingArrows.module.css";

export const RenderSortArrow = ({
  column,
  onSort,
  activeColumn,
  activeOrder,
}) => {
  const { sortOrder, sortColumn } = useSortingContext();

  const handleSort = (order) => {
    sortColumn.current = column;
    sortOrder.current = order;

    onSort();
  };
  const isActive = activeColumn === column;
  const isAscending = isActive && activeOrder === "asc";
  const isDescending = isActive && activeOrder === "desc";

  return (
    <div className={styles.tableArrow}>
      <div
        className={`${styles.arrowAscendingStyling}  ${
          isAscending ? styles.active : ""
        }`}
        onClick={() => handleSort("asc")}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 4L4 16H20L12 4Z" />
        </svg>
      </div>
      <div
        className={`${styles.arrowDescendingStyling}  ${
          isDescending ? styles.active : ""
        } `}
        onClick={() => handleSort("desc")}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 20L4 8H20L12 20Z" />
        </svg>
      </div>
    </div>
  );
};
