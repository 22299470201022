import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const passwordChange = async ({ userId, password }) => {
  const user = await getUser();

  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/${userId}/reset-password`;

  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  const requestBody = {
    type: "password",
    value: password,
  };

  try {
    const response = await api.put(apiUrl, requestBody, { headers });

    return { requestStatus: response.status, error:response.data.error_description };
  } catch (error) {
    console.error("Error changing password:", error);
  }
};
