import React, { useState, useEffect } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import searchbar from "../../../../images/search-bar.png";
import date from "../../../../images/date-icon.png";
import { useTranslation } from "react-i18next";
import useExport from "../../../shared/useExport";
import { useSortingContext } from "../../../../context/sortingContext";
import styles from "./tableFilter.module.css";
export const TableFilters = ({
  handleSearchFormSubmit,
  requestBody,
  selectedDateRange,
  setSelectedDateRange,
  dateRangeRef,
  fetchData,
}) => {
  const { t } = useTranslation();
  const { sortOrder, sortColumn } = useSortingContext();
  const [showCalendar, setShowCalendar] = useState(false);
  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);

    const startDate = moment(ranges.selection.startDate).format("DD-MM-YYYY");
    const endDate = moment(ranges.selection.endDate).format("DD-MM-YYYY");

    requestBody.current = {
      ...requestBody.current,
      startDate: startDate,
      endDate: endDate,
    };
    fetchData();
  };

  const { handleExport } = useExport();
  async function exportFunction() {
    const sortingOrder = sortOrder.current;
    const sortingColumn = sortColumn.current;
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/project/export-project-list?sortBy=${sortingColumn}&sortOrder=${sortingOrder}`;
    const fileName = "ProjectList_ " + new Date().toLocaleDateString();
    handleExport(fileName, apiUrl, requestBody.current);
  }
  const toggleCalender = () => {
    setShowCalendar((prev) => !prev);
  };
  const toggleClearFilter = () => {
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.reportListDetailsContent}>
      <div className={styles.contentOptions}>
        <div className={styles.searchOption}>
          <div className={styles.searchInput}>
            <img src={searchbar} alt="home icon" />
            <input
              className="checkInputProjectList"
              type="text"
              placeholder={t("search")}
              value={requestBody.current.searchText || ""}
              onChange={handleSearchFormSubmit}
            />
          </div>
        </div>

        <div className={styles.contentButton}>
          <img src={date} alt="date icon" className={styles.dateIcon} />
          <button className={styles.btnDate} onClick={() => toggleCalender()}>
            <span>{t("dateFilter_projectList")}</span>
          </button>
          <div className={styles.dateRange} ref={dateRangeRef}>
            {showCalendar && (
              <DateRangePicker
                ranges={selectedDateRange}
                onChange={handleDateChange}
              />
            )}
          </div>
        </div>

        <div className={styles.contentButton}>
          <button className={styles.btnClear} onClick={toggleClearFilter}>
            <span>{t("clearFilter_projectList")}</span>
          </button>
        </div>

        <div className={styles.export}>
          <button className={styles.btn} onClick={exportFunction}>
            <span>{t("exportBtn_projectList")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};
