import React, { createContext, useState, useContext } from "react";

const ProjectRoleContext = createContext();

export const ProjectRoleProvider = ({ children }) => {
  const [dataProjectRole, setDataProjectRole] = useState({});

  return (
    <ProjectRoleContext.Provider
      value={{ dataProjectRole, setDataProjectRole }}
    >
      {children}
    </ProjectRoleContext.Provider>
  );
};

export const useProjectRole = () => useContext(ProjectRoleContext);
