import DatePicker from "react-datepicker";

export const NormalColumnSettings = ({
  handleSubmit,
  validationErrors,
  userDetails,
  updateUser,
   userOrg,
  t,
  setValidationErrors,
  updateRole,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handlePhoneNoChange = (inputValue) => {
    const validPhoneNo = /^[\d+()-]*$/.test(inputValue);

    if (validPhoneNo || inputValue === "") {
      updateUser(inputValue, "contactNo");
    } else {
      const newValidationErrors = {};
      newValidationErrors["contactNo"] = `${t("phoneNo_errorMessage")}`;
      setValidationErrors(newValidationErrors);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div class="first-row">
        <div
          className={`rows-content ${
            validationErrors.firstName ? "error" : ""
          }`}
        >
          <label>
            {t("firstName")} <span className="required">*</span>
          </label>
          <input
            class="form-control"
            value={userDetails.user.firstName}
            onChange={(e) => updateUser(e.target.value, "firstName")}
            type="text"
            disabled={userOrg == null}
          />
          {validationErrors.firstName && (
            <span className="error-message">{validationErrors.firstName}</span>
          )}
        </div>
        <div
          className={`rows-content ${validationErrors.lastName ? "error" : ""}`}
        >
          <label>
            {t("lastName")} <span className="required">*</span>
          </label>
          <input
            class="form-control "
            value={userDetails.user.lastName}
            onChange={(e) => updateUser(e.target.value, "lastName")}
            type="text"
            disabled={userOrg == null }
          />
          {validationErrors.lastName && (
            <span className="error-message">{validationErrors.lastName}</span>
          )}
        </div>
      </div>
      <div class="second-row">
        <div
          className={`rows-content ${validationErrors.email ? "error" : ""}`}
        >
          <label>
            {t("emailAddress")} <span className="required">*</span>
          </label>
          <input
            class="form-control"
            value={userDetails.user.email}
            onChange={(e) => updateUser(e.target.value, "email")}
            type="text"
            disabled={userOrg == null }
          />
          {validationErrors.email && (
            <span className="error-message">{validationErrors.email}</span>
          )}
        </div>
        <div
          className={`rows-content ${
            validationErrors.contactNo ? "error" : ""
          }`}
        >
          <label>{t("phoneNo")}</label>
          <input
            class="form-control"
            value={userDetails.user.contactNo}
            onChange={(e) => handlePhoneNoChange(e.target.value)}
            type="text"
            maxLength={18}
            disabled={userOrg == null }
          />
          {validationErrors.contactNo && (
            <span className="error">{validationErrors.contactNo}</span>
          )}
        </div>
      </div>
      {userOrg != null && (
        <div>
          <div class="third-row">
            <div
              className={`rows-content ${
                validationErrors.roleType ? "error" : ""
              }`}
            >
              <label>
                {t("role")} <span className="required">*</span>
              </label>
              <div>
                <select
                  className="form-control"
                  value={userDetails.role ? userDetails.role.name : ""}
                  onChange={(e) => updateRole(e.target.value, "name")}
                >
                  <option>
                    {userDetails.role ? userDetails.role.name : ""}
                  </option>
                  <option value="SUPRMEN_ADMIN">
                    {t("suprmenAdmin_dropDownMenu")}
                  </option>
                  <option value="ORG_ADMIN">
                    {t("orgAdmin_dropDownMenu")}
                  </option>
                  <option value="REGULAR">{t("regular_dropDownMenu")}</option>
                  <option value="VIEWER">{t("viewer_dropDownMenu")}</option>
                </select>
                {validationErrors.roleType && (
                  <span className="error-message">
                    {validationErrors.roleType}
                  </span>
                )}
              </div>
            </div>
            <div
              className={`rows-content ${
                validationErrors.status ? "error" : ""
              }`}
            >
              <label>
                {t("status")} <span className="required">*</span>
              </label>
              <div>
                <select
                  className="form-control"
                  value={userDetails.user.status}
                  onChange={(e) => updateUser(e.target.value, "status")}
                >
                  <option>{userDetails.user.status}</option>
                  <option value="ACTIVE">{t("active_dropDownMenu")}</option>
                  <option value="INACTIVE">{t("inactive_dropDownMenu")}</option>
                </select>
                {validationErrors.status && (
                  <span className="error-message">
                    {validationErrors.status}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div class="fourth-row">
            <div
              className={`rows-content ${
                validationErrors.startDate ? "error" : ""
              }`}
            >
              <label>
                {t("startDate")} <span className="required">*</span>
              </label>
              <div className="date form-control">
                <DatePicker
                  class="form-control"
                  selected={startDate}
                  onChange={(date) => {
                    validationErrors.startDate = null;
                    setStartDate(date);
                  }}
                />

                {validationErrors.startDate && (
                  <span className="error-message">
                    {validationErrors.startDate}
                  </span>
                )}
              </div>
            </div>
            <div
              className={`rows-content ${
                validationErrors.endDate ? "error" : ""
              }`}
            >
              <label>
                {t("endDate")} <span className="required">*</span>
              </label>
              <div className="date form-control">
                <DatePicker
                  class="form-control"
                  selected={endDate}
                  onChange={(date) => {
                    validationErrors.endDate = null;
                    setEndDate(date);
                  }}
                />

                {validationErrors.endDate && (
                  <span className="error-message">
                    {validationErrors.endDate}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div class="fifth-row">
            <div class="rows-content">
              <label>{t("language_createEditUser")}</label>
              <div>
                <select
                  className="form-control"
                  value={userDetails.user.language}
                  onChange={(e) => updateUser(e.target.value, "language")}
                >
                  <option>{userDetails.user.language}</option>
                  <option value="ENGLISH">Engels</option>
                  <option value="DUTCH">Nederlands</option>
                </select>
              </div>
            </div>
            <div class="rows-content">
              <label>{t("jobTitle_createEditUser")}</label>
              <input
                class="form-control"
                value={userDetails.user.jobTitle}
                onChange={(e) => updateUser(e.target.value, "jobTitle")}
                type="text"
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
};
