import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export async function postColumnPreference(updatedColumns) {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/selected-columns`;

  const userPreference = {
    page: "ANNOTATIONS",
    selectedColumns: Object.keys(updatedColumns)
      .filter((key) => updatedColumns[key])
      .join(","),
  };

  try {
    await api.post(apiUrl, userPreference, { headers });
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
