import { proscessingStatus } from "../../api/processingStatus";
import React, { useEffect, useState, useCallback } from "react";
import styles from "./reportButton.module.css";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import { reportAnalysis } from "../../api/reportAnalysis";
import { useTranslation } from "react-i18next";
import caution from "../../../../images/caution.png";

export const ReportButton = ({
  id,
  projectId,
  projectRole,
  omniBaseUrlId,
  orgId,
  currentMasterRole,
  masterUserOrgId,
}) => {
  const [status, setStatus] = useState();
  const { t } = useTranslation();
  const [role, setRole] = useState();
  const getProcessingStatus = useCallback(async () => {
    const reponse = await proscessingStatus(id);
    setStatus(reponse.status);
  }, [id]);

  const handleAnalysisClick = async () => {
    try {
      await reportAnalysis(id);
      toast.success(`${t("analysisInitiated")}`);
    } catch (error) {
      toast.error("An unexpected error occurred: " + error.message);
    }
  };

  const getAnalysisNames = (processingStatus) => {
    switch (processingStatus) {
      case "NOT_STARTED":
        return `${t("reports")}`;
      case "IN_PROGRESS":
        return `${t("processing")}`;
      case "COMPLETED":
        return `${t("reports")}`;
      case "FAILED":
        return `${t("retryProcessing")}`;

      default:
        return `${t("reports")}`;
    }
  };

  useEffect(() => {
    getProcessingStatus();

    const interval = setInterval(() => {
      getProcessingStatus();
    }, 5000);
    return () => clearInterval(interval);
  }, [getProcessingStatus]);

  useEffect(() => {
    const result = projectRole[projectId].role;
    setRole(result);
  }, [projectId, projectRole]);

  const getAnalysisButtonClass = (processingStatus) => {
    switch (processingStatus) {
      case "NOT_STARTED":
        return `${styles.notStarted}`;
      case "STARTED":
        return ` ${styles.red}`;
      case "IN_PROGRESS":
        return ` ${styles.orange}`;
      case "COMPLETED":
        return `  ${styles.green}`;
      case "FAILED":
        return `${styles.failed}`;
      default:
        return ` ${styles.gray}`;
    }
  };
  return (
    <>
      <button
        onClick={() => {
          if (status === "COMPLETED") {
            window.location.href = `/report/annotations/${id}/${role}/${orgId}`;
          } else if (status === "IN_PROGRESS") {
            toast.error(`${t("analyse_error")}`);
          } else {
            handleAnalysisClick();
          }
        }}
        className={`${getAnalysisButtonClass(status)} ${
          omniBaseUrlId === null ||
          (!(
            currentMasterRole.includes("SUPRMEN_ADMIN")||
            role === "PROJECT_LEADER" ||
            (masterUserOrgId &&
              (currentMasterRole.includes("REGULAR") ||
                currentMasterRole.includes("ORG_ADMIN")) &&
              role === "PROJECT_USER")
          ) &&
            status !== "COMPLETED")
            ? styles.disabled
            : ""
        }`}
        disabled={
          omniBaseUrlId === null ||
          (!(
            currentMasterRole.includes("SUPRMEN_ADMIN")||
            role === "PROJECT_LEADER" ||
            (masterUserOrgId &&
              (currentMasterRole.includes("REGULAR") ||
                currentMasterRole.includes("ORG_ADMIN")) &&
              role === "PROJECT_USER")
          ) &&
            status !== "COMPLETED")
        }
      >
        {status === ("IN_PROGRESS" || "FAILED") ? (
          <Circles type="TailSpin" color="white" height={18} width={18} />
        ) : null}
        <a>{getAnalysisNames(status)}</a>
      </button>
      {status === "FAILED" && (
        <>
          <img class={styles.cautionImage} src={caution} alt="error" />
        </>
      )}
    </>
  );
};
