import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";

export const startAnalysis = async (projectBuildingId) => {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/start-analysis`;

  try {
    const response = await api.post(apiUrl, { projectBuildingId }, { headers });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Failed to start analysis."
    );
  }
};
