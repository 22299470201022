import { PageHeader } from "./components/PageHeader/PageHeader";
import { useParams, useNavigate } from "react-router-dom";
import { TableHeader } from "./components/TableInfo/TableInfo";
import { TableFilters } from "./components/Filters/Filters";
import { DataTable } from "./components/DataTable/DataTable";
import { PageUtility } from "./components/PageUtility/PageUtility";
import styles from "./JsonReport.module.css";
import { fetchJsonReportData } from "./Api/fetchJsonReportData";
import { useEffect, useCallback, useState, useRef } from "react";
import { exportData } from "./Api/exportData";
import { startAnalysis } from "../projectList/components/api/startAnalysis";
import { toast } from "react-toastify";
import { RefreshOverLayFile } from "./components/PageUtility/overLayFile";
import { useSortingContext } from "../../context/sortingContext";
import { useFetchDataContext } from "../../context/jsonReportDataContext";

export const JsonReport = ({
  suprmenAdmin,
  currentMasterRole,
  masterUserOrgId,
}) => {
  const { sortOrder, sortColumn } = useSortingContext();
  const { isAiGeneratedOn } = useFetchDataContext();
  const params = useParams();
  const navigate = useNavigate();
  const buildingId = params.id;
  const projectRole = params.projectRole;
  const currentPage = useRef(1);
  const selectedPageSize = useRef(10);
  const requestBody = useRef({
    searchText: null,
    elementNames: null,
    types: null,
    locations: null,
  });

  const [tableData, setTableData] = useState([]);
  const [totalPageNumbers, setTotalPageNumbers] = useState(0);
  const [isRefreshInitiated, setRefreshInitiated] = useState(false);
  const [currentToggle, setCurrentToggle] = useState(false);
  const [conditieMetingToggle, setConditieMetingToggle] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [summaryData, setSummaryData] = useState({
    sumOfArea: 0,
    sumOfGrossTotal: 0,
    sumOfLength: 0,
    sumOfLengthTotal: 0,
    sumOfNetTotal: 0,
    sumOfQuantity: 0,
  });

  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
  };

  const handlePageChange = (newPageNumber) => {
    currentPage.current = newPageNumber;
    fetchTableData();
  };
  const pageNumber = currentPage.current;
  const handlePageSize = async (selectedSize) => {
    selectedPageSize.current = selectedSize;
    fetchTableData();
  };

  const handleElementFilterSearch = (filterArray) => {
    requestBody.current = {
      ...requestBody.current,
      elementNames: filterArray,
    };
    fetchTableData();
  };

  const handleTypeFilterSearch = (filterArray) => {
    requestBody.current = {
      ...requestBody.current,
      types: filterArray,
    };
    fetchTableData();
  };

  const handleLocationFilterSearch = (filterArray) => {
    requestBody.current = {
      ...requestBody.current,
      locations: filterArray,
    };
    fetchTableData();
  };

  const handleAnalysisClick = useCallback(async () => {
    try {
      await startAnalysis(buildingId);
    } catch (error) {
      toast.error("An unexpected error occurred: " + error.message);
    }
  }, [buildingId]);

  const handleSearchFormSubmit = (event) => {
    const { value } = event.target;
    requestBody.current = {
      ...requestBody.current,
      searchText: value,
    };
    fetchTableData();
  };

  const resetFilter = (filterName) => {
    if (filterName === "element") {
      requestBody.current = {
        ...requestBody.current,
        elementNames: null,
      };
    } else if (filterName === "type") {
      requestBody.current = {
        ...requestBody.current,
        types: null,
      };
    } else if (filterName === "location") {
      requestBody.current = {
        ...requestBody.current,
        locations: null,
      };
    }
    fetchTableData();
  };

  const fetchTableData = useCallback(async () => {
    try {
      const TableData = await fetchJsonReportData(
        buildingId,
        selectedPageSize.current,
        currentPage.current,
        requestBody.current,
        sortColumn.current,
        sortOrder.current
      );

      if (TableData && TableData.apiTableData) {
        const totalPages = TableData.apiTableData.totalPages || 0;

        if (currentPage.current > totalPages && totalPages > 0) {
          currentPage.current = totalPages;
          return fetchTableData();
        }

        setTableData(TableData.apiTableData.data || []);
        setTotalPageNumbers(totalPages);
        setSummaryData({
          sumOfArea: TableData.apiTableData.sumOfArea || 0,
          sumOfGrossTotal: TableData.apiTableData.sumOfGrossTotal || 0,
          sumOfLength: TableData.apiTableData.sumOfLength || 0,
          sumOfLengthTotal: TableData.apiTableData.sumOfLengthTotal || 0,
          sumOfNetTotal: TableData.apiTableData.sumOfNetTotal || 0,
          sumOfQuantity: TableData.apiTableData.sumOfQuantity || 0,
        });
      } else {
        setTableData([]);
        setTotalPageNumbers(0);
        setSummaryData({
          sumOfArea: 0,
          sumOfGrossTotal: 0,
          sumOfLength: 0,
          sumOfLengthTotal: 0,
          sumOfNetTotal: 0,
          sumOfQuantity: 0,
        });
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
      setTableData([]);
      setTotalPageNumbers(0);
    }
  }, [buildingId, sortColumn, sortOrder]);

  const checkAiGeneratedToggle = useCallback(() => {
    const { isAiGenerated, ...rest } = requestBody.current;
    requestBody.current = isAiGeneratedOn
      ? { ...rest, isAiGenerated: true }
      : rest;
    fetchTableData();
  }, [fetchTableData, isAiGeneratedOn]);

  const exportTableData = async () => {
    const fileName = "Annotations";
    try {
      await exportData({
        id: buildingId,
        sortColumn: sortColumn.current,
        sortOrder: sortOrder.current,
        exportBody: requestBody.current,
        fileName,
      });
    } catch (error) {
      console.error("Error exporting table data:", error);
    }
  };

  const pageReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    fetchTableData();
    setRefreshInitiated(false);
  }, [fetchTableData, sortColumn, sortOrder]);

  useEffect(() => {
    if (isRefreshInitiated) {
      handleAnalysisClick();
    }
  }, [handleAnalysisClick, isRefreshInitiated, navigate]);

  useEffect(() => {
    checkAiGeneratedToggle();
  }, [checkAiGeneratedToggle]);

  return (
    <div className={styles.mainWrap}>
      {isRefreshInitiated && (
        <RefreshOverLayFile id={buildingId} pageReload={pageReload} />
      )}
      <div className={styles.quoteBody}>
        <PageUtility onCheckboxChange={handleCheckboxChange} />

        <div
          className={`${styles.portfolio} ${
            isChecked ? styles.portfolioChecked : styles.portfolioUnchecked
          }`}
        >
          <PageHeader />
          <div className={`${styles.pageBody} ${styles.contentHt}`}>
            <div className={styles.jsonListRecord}>
              <div className={styles.jsonListDetails}>
                <TableHeader
                  buildingId={buildingId}
                  setRefreshInitiated={setRefreshInitiated}
                  projectRole={projectRole}
                  suprmenAdmin={suprmenAdmin}
                  fetchTableData={fetchTableData}
                  masterUserOrgId={masterUserOrgId}
                  currentMasterRole={currentMasterRole}
                  orgId={params.orgId}
                />
                <TableFilters
                  requestBody={requestBody}
                  handleSearchFormSubmit={handleSearchFormSubmit}
                  tableData={tableData}
                  buildingId={buildingId}
                  handleElementFilterSearch={handleElementFilterSearch}
                  handleTypeFilterSearch={handleTypeFilterSearch}
                  handleLocationFilterSearch={handleLocationFilterSearch}
                  resetFilter={resetFilter}
                  fetchTableData={fetchTableData}
                  exportTableData={exportTableData}
                  setCurrentToggle={setCurrentToggle}
                  setConditieMetingToggle={setConditieMetingToggle}
                />
                <DataTable
                  tableData={tableData}
                  handlePageSize={handlePageSize}
                  handlePageChange={handlePageChange}
                  totalPageNumbers={totalPageNumbers}
                  pageNumber={pageNumber}
                  currentToggle={currentToggle}
                  summaryData={summaryData}
                  fetchTableData={fetchTableData}
                  conditieMetingToggle={conditieMetingToggle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
