import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const accLinkReactivation = async ({ userId }) => {
  const user = await getUser();

  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/users/resendAccountActivationLink?editUserId=${userId}`;

  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  try {
    const response = await api.put(apiUrl, null, { headers });

    return { requestStatus: response.status };
  } catch (error) {
    console.error("Error resending activation link:", error);
  }
};
