import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const saveColumnVisibility = async (updatedColumns) => {
    const user = await getUser();
    const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/user-preference/selected-columns`;

    const userPreference = {
      page: "PROJECT",
      selectedColumns: Object.keys(updatedColumns)
        .filter((key) => updatedColumns[key])
        .join(","),
    };

    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };

    try {
      await api.post(apiUrl, userPreference, { headers });
    } catch (error) {
      console.error("Error saving column selection:", error);
    }
  };