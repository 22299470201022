import React, { createContext, useContext } from "react";

// Create a context with default values
const AccessContext = createContext({
  suprmenAdmin: false,
  orgAdmin: false,
  viewer: false,
  regular: false,
});

// A hook to use the context in other components
export const useAccess = () => {
  return useContext(AccessContext);
};

// A provider component to wrap around components that need access
export const AccessProvider = ({
  children,
  suprmenAdmin,
  orgAdmin,
  viewer,
  regular,
}) => {
  return (
    <AccessContext.Provider value={{ suprmenAdmin, orgAdmin, viewer, regular }}>
      {children}
    </AccessContext.Provider>
  );
};
